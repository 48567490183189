import { Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: '#ffcb6b',
        marginLeft: '0.5vw',
        width: '6vw'
    }
}))

export const QuizTag: React.FC = () => {
    const classes = useStyles()

    return (
        <Button className={classes.button} disabled>
            <Typography style={{ textTransform: 'lowercase' }} color="textSecondary" variant="body2">
                Opdateret
            </Typography>
        </Button>
    )
}
