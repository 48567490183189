import { makeStyles } from '@material-ui/core/styles'

import { beige } from '../utils/colors'

export const buttonStyles = makeStyles((theme) => ({
    columnContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 10
    },
    editQuestionButton: {
        border: '2px solid #000',
        cursor: 'pointer',
        margin: 8,
        transition: '0.3s',
        msTransition: 'ease-in',
        padding: 5,
        borderColor: '#063354',
        borderRadius: 5,
        '&:hover, &:focus': {
            backgroundColor: '#063354',
            color: '#FFFFFF',
        }
    },
    otherButtons: {
        marginLeft: '2%',
        position: 'relative',
        zIndex: 5
    },
    leftButton: {
        marginLeft: '7.5%',
        position: 'relative',
        zIndex: 5
    },
    authButton: {
        position: 'relative',
        zIndex: 5
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    saveButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#28a745',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#28a745',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#28a745',
            color: '#FFFFFF',
        }
    },
    alertButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#ffc107',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#ffc107',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#ffc107',
            color: '#FFFFFF',
        }
    },
    cancelButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#dc3545',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#dc3545',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#dc3545',
            color: '#FFFFFF',
        }
    },
    uploadButton: {
        backgroundColor: '#03a9f4',
        color: 'white',
        margin: 10,
        width: '100%',
        fontWeight: 600,
        marginRight: theme.spacing(1),
        borderWidth: 1,
        borderColor: '#03a9f4',
        borderRadius: 5,
        borderStyle: 'solid',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#063354',
            color: '#FFFFFF',
            borderColor: '#063354',

        }
    },
    logoutButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: '#FFF',
        borderColor: '#dc3545',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#dc3545',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#dc3545',
            color: '#FFFFFF',
        }
    },
    dropBoxButton: {
        backgroundColor: '#0196bb',
        '&:hover': {
            backgroundColor: '#005778',
            color: 'white',
        }
    },
    nextForward: {
        backgroundColor: '#0196bb',
        color: 'white',
        marginTop: 10,
        marginBottom: 10,
        '&:hover': {
            backgroundColor: '#005778',
            color: 'white',
        }
    },
    largeAuthButton: {
        width: '50%',
        minWidth: 200,
        maxWidth: 350,
        height: 55,
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#3C8CAC',
        cursor: 'pointer',
        fontSize: 20,
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10
    },
    largeUniButton: {
        width: '50%',
        minWidth: 200,
        maxWidth: 350,
        height: 55,
        borderRadius: 5,
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        borderStyle: 'solid',
        borderColor: '#3C8CAC',
        cursor: 'pointer',
        fontSize: 20,
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
        '&:hover, &:focus, &active': {
            backgroundColor: '#3C8CAC',
            color: '#FFFFFF',
        },
    }
}))
