/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Paper, TableContainer
} from '@material-ui/core'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import GoBackHeader from '../../components/atoms/GoBackHeader'
import { QuizItemComponent } from '../../components/molecules/QuizItemComponent'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { getCommunityQuizById } from '../../utils/Services/community-quiz-service'
import { useTableStyles } from '../../utils/styles'
import { StudentQuizType } from '../../utils/types'
import { Loading } from '../Loading'

export const StudentQuizItem: React.FC = () => {
    const classes = useTableStyles()
    const history = useHistory()
    const location: any = useLocation()
    const quizId = location.state ? location.state.id : undefined

    const [quiz, setQuiz] = React.useState<StudentQuizType>()
    const [loading, setLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            const fetchedQuiz = await getCommunityQuizById(quizId)
            setLoading(false)
            setQuiz(fetchedQuiz)
        })()
    }, [])

    return (
        <BodyWithTopBar>
            {loading ? <Loading />
                : (
                    <TableContainer className={classes.tableWithoutBottomButtons} component={Paper}>
                        <GoBackHeader headerText={quiz ? quiz.title : ''} onClick={() => history.goBack()} />
                        {quiz && (
                            <QuizItemComponent quiz={quiz} />
                        )}
                    </TableContainer>
                )}
        </BodyWithTopBar>
    )
}
