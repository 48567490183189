import React from 'react'

import { VimeoVideoInput } from './VimeoVideoInput'
import { YoutubeVideoInput } from './YoutubeVideoInput'

type Props = {
    youtubeVideoId: string,
    onChange: (vimeoVideo: string) => void,
    vimeoVideoId: string,
    onChangeVimeo: (vimeoVideoId: string) => void
}

export const VideoInput: React.FC<Props> = ({
    youtubeVideoId, onChange, onChangeVimeo, vimeoVideoId
}) => {
    const [value, setValue] = React.useState(vimeoVideoId || '')
    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center'
        }}>
            <VimeoVideoInput
                value={value}
                setValue={setValue}
                isDisabled={youtubeVideoId.length > 0}
                vimeoVideoId={vimeoVideoId}
                onChange={(v) => onChangeVimeo(v.id)} />
            <YoutubeVideoInput
                value={youtubeVideoId}
                onChange={onChange}
                isDisabled={value.length > 0}/>
        </div>
    )
}
