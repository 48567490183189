import { FormGroup } from '@material-ui/core'
import * as React from 'react'

import {
    gradeLevels, indskolingGradeLevels, mellemtrinGradeLevels,
    possibleQuizCategories, possibleQuizTopics, udskolingGradeLevels
} from '../../utils/globalData'
import { getGradesByKeyword } from '../../utils/helpers'
import { BasicMultipleSelect } from '../atoms/BasicMultipleSelect'
import SwitchWithSideText from '../atoms/SwitchWithSideText'
import { TextInputTemplate } from './TextInputTemplate'

interface Props {
    title: string,
    setTitle: (title: string) => void,
    topics: string[],
    setTopics: (topics: string[]) => void,
    categories: string[],
    setCategory: (categories: string[]) => void,
    openQuiz: boolean,
    publicQuiz: boolean,
    setPublicQuiz: (isPublic: boolean) => void,
    setOpenQuiz: (isOpen: boolean) => void,
    selectedGrades: string[],
    setSelectedGrades: (selectedGrades: string[]) => void
}

export const FirstCreateCommunityQuizStep: React.FC<Props> = ({
    title,
    setTitle,
    categories,
    topics,
    setTopics,
    setCategory,
    openQuiz,
    publicQuiz,
    setPublicQuiz,
    setOpenQuiz, selectedGrades, setSelectedGrades
}) => {

    const handleGradesChange = (event: any) => {
        if (event.target.value.length === 0) setSelectedGrades([])
        else {
            let newGrades = event.target.value as string[]

            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'indskoling', indskolingGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'mellemtrin', mellemtrinGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'udskoling', udskolingGradeLevels, selectedGrades)

            setSelectedGrades(newGrades)
        }
    }
    return (
        <FormGroup>
            <TextInputTemplate
                value={title}
                onChange={setTitle}
                label="Giv quiz en navn" />
            <BasicMultipleSelect
                label="Kategori"
                value={categories}
                setValue={setCategory}
                possibleValues={possibleQuizCategories} />
            <BasicMultipleSelect
                label="Emner"
                value={topics}
                setValue={setTopics}
                possibleValues={possibleQuizTopics} />
            <BasicMultipleSelect
                label="Klassetrin"
                value={selectedGrades}
                setValue={() => true}
                onChange={handleGradesChange}
                possibleValues={gradeLevels} />
            <SwitchWithSideText
                falseText="Lukket"
                trueText="Åben"
                checked={openQuiz}
                setChecked={setOpenQuiz} />
            <SwitchWithSideText
                falseText="Privat"
                trueText="Offentlig"
                checked={publicQuiz}
                setChecked={setPublicQuiz} />
        </FormGroup>
    )
}
