/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Chip, createStyles, Grid, Link, makeStyles, Typography
} from '@material-ui/core'
import * as React from 'react'
import ReactPlayer from 'react-player'

import { Question, QuizLibraryType } from '../../utils/types'

interface Props {
    quiz: QuizLibraryType,
}

const useStyles = makeStyles(() => createStyles({
    videoContainer: {
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column',
        maxWidth: '100%',
    },
    answersContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: '1%'
    },
    questionListContainer: {
        display: 'flex', flexDirection: 'column'
    }
}))

export const QuizItemComponent: React.FC<Props> = ({ quiz }) => {
    // const { isTeacherLikeAParent } = useTeacher()
    const classes = useStyles()
    const [videoLoading, setVideoLoading] = React.useState<boolean>(false)
    const [selectedQuestion, setSelectedQuestion] = React.useState<Question>()

    React.useEffect(() => {
        (async () => {
            if (quiz && quiz.questions && quiz.questions.length > 0) {
                setSelectedQuestion(quiz.questions[0])
            }
        })()
    }, [])

    const onQuestionItemClick = (item: Question) => {
        setSelectedQuestion(item)

        if (item?.vimeoVideoId && item.vimeoVideoId !== selectedQuestion?.vimeoVideoId) setVideoLoading(true)
        if (item?.youtubeVideoId && item.youtubeVideoId !== selectedQuestion?.youtubeVideoId) setVideoLoading(true)
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={10}>
                <div style={{ textAlign: 'center' }}>
                    {quiz?.file?.uri && (
                        <a color="primary" href={quiz?.file?.uri}>
                            Download tilhørende PDF
                        </a>
                    )}
                </div>
                {selectedQuestion
                    && (
                        <div className={classes.videoContainer}>
                            <Typography variant="body1" style={{ textAlign: 'center' }}>
                                {selectedQuestion.title}
                            </Typography>
                            {selectedQuestion.explanation.length > 1 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        marginBottom: 15,
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        paddingLeft: 15,
                                        textAlign: 'start',
                                        border: '1px solid #ccc'
                                    }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={
                                        { __html: selectedQuestion.explanation as string }
                                    } />
                            )}
                            <>
                                {selectedQuestion.picture?.uri && (
                                    <div
                                        style={{

                                            display:
                                                'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginBottom: 30
                                        }}>

                                        <img
                                            alt="question"
                                            src={selectedQuestion.picture.uri}
                                            style={{
                                                maxWidth: 700,
                                                maxHeight: 700,

                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover'
                                            }} />

                                    </div>
                                )}
                            </>

                            {(selectedQuestion?.vimeoVideoId || selectedQuestion?.youtubeVideoId) && (
                                <ReactPlayer
                                    url={
                                        selectedQuestion.vimeoVideoId
                                            ? `https://player.vimeo.com/video/${selectedQuestion.vimeoVideoId}`
                                            : `https://www.youtube.com/watch?v=${selectedQuestion.youtubeVideoId}`
                                    }
                                    onReady={() => setVideoLoading(false)}
                                    onError={() => setVideoLoading(false)}
                                    width="100%"
                                    controls />
                            )}

                            <div className={classes.answersContainer}>
                                {selectedQuestion.responses && selectedQuestion.responses.map((item: any) => (
                                    <Chip key={item} style={{ margin: 2 }} label={item} />
                                ))}
                            </div>
                        </div>
                    )}
            </Grid>
            <Grid item xs={12} sm={2}>
                <div className={classes.questionListContainer}>
                    {quiz?.questions && quiz.questions.map((item: any) => (

                        // eslint-disable-next-line react/jsx-no-comment-textnodes
                        <div style={{ display: 'flex' }}>
                            <Link
                                key={item.title}
                                onClick={() => onQuestionItemClick(item)}
                                style={{
                                    cursor: 'pointer'
                                }}
                                component="button"
                                disabled={videoLoading}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        textAlign: 'center',
                                        // eslint-disable-next-line no-nested-ternary
                                        color: 'inherit'
                                    }}>
                                    {selectedQuestion?.title === item.title
                                        ? <b>{item.title}</b>
                                        : item.title}
                                </Typography>
                            </Link>
                        </div>
                    ))}
                </div>
            </Grid>
        </Grid>
    )
}
