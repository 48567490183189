import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import moment from 'moment'
import React from 'react'

import Routes from '../../utils/routes'
import {
    AssignedHomeworkStatusType, AssignedHomeworkType, AssignedQuizType 
} from '../../utils/types'
import { LinkButton } from '../atoms/LinkButton'
import { QuizTag } from '../atoms/QuizUpdatedTag'

interface Props {
    homework: AssignedHomeworkType;
}

export const HomeworkRow: React.FC<Props> = ({ homework }: Props) => {
    const showButton = (
        (
            homework.status === AssignedHomeworkStatusType.NotStarted
            || homework.status === AssignedHomeworkStatusType.Started
            || homework.status === AssignedHomeworkStatusType.Finished
        )
        && !homework.isExpired
    )
    const isOneQuizUpdated = (quiz: AssignedQuizType) => {
        return quiz.lastUpdated && moment.utc(quiz.lastUpdated).isAfter(moment.utc().subtract(10, 'days'))
    }
    return (
        <TableRow>
            <TableCell component="th" scope="row" style={{ alignItems: 'center' }}>
                {homework.name}
                {homework.quizzes.some(isOneQuizUpdated) && !homework.isExpired && (
                    <QuizTag />
                )}
            </TableCell>
            <TableCell align="left">{moment.utc(homework.expirationDate).local().format('DD/MM/YYYY')}</TableCell>
            <TableCell align="center">
                {showButton && (
                    <LinkButton
                        text="Se lektie"
                        to={`${Routes.Lesson.path}/${homework.id}`}
                        color="primary" />
                )}
            </TableCell>
        </TableRow>
    )
}
