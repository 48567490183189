import {
    Container,
    Typography
} from '@material-ui/core'
import React from 'react'
import ReactPlayer from 'react-player'

import { homeworkStyles } from '../../styles/HomeworkStyle'
import { VideoType } from '../../utils/types'

interface Props {
    v: VideoType
}

export const VideoItem: React.FC<Props> = ({
    v
}) => {
    const classesHomework = homeworkStyles()
    return (
        <div key={v?.id} className={classesHomework.root}>
            <>
                <Container className={classesHomework.anchorContainer}>
                    <div>
                        {v?.mainFile?.uri && (
                            <a color="primary" className={classesHomework.anchor} href={v?.mainFile?.uri}>
                                Download tilhørende PDF
                            </a>
                        )}
                    </div>
                </Container>
            </>
            <Typography className={classesHomework.heading}>{v.title}</Typography>
            <div className={classesHomework.videosDiv}>
                <ReactPlayer
                    url={`https://player.vimeo.com/video/${v.vimeoVideoId}`}
                    width={740}
                    height={480}
                    controls />
            </div>
        </div>
    )
}
