import * as React from 'react'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { UniLoginReply, useUniLoginReply } from '../../utils'
import { showMessage } from '../../utils/notifications'
import { finalizeSignUpStudentWithUniLogin } from '../../utils/Services/student-services'
import { StudentType } from '../../utils/types'
import { fireWrap } from '../../utils/wrapers'
import { Loading } from '../Loading'

let finalizePromise: Promise<StudentType>

export const CompleteSignUpWithUniLogin: React.FC = () => {
    const [busy, setBusy] = useState(true)
    const [student, setStudent] = useState<StudentType>()

    const uniLoginReply = useUniLoginReply<{ id: string } & UniLoginReply>()

    if (!uniLoginReply || !uniLoginReply.id) {
        showMessage('Whoops! There was an error.', 'error')
        return <Redirect to="/"/>
    }

    useEffect(() => {
        fireWrap(async () => {
            if (!finalizePromise) {
                finalizePromise = finalizeSignUpStudentWithUniLogin({
                    uniLoginUsername: uniLoginReply.user,
                    uniLoginAuth: uniLoginReply.auth,
                    uniLoginTimestamp: uniLoginReply.timestamp,
                    group: 'student',
                    signUpIntentId: uniLoginReply.id
                })
            }
            setStudent(
                await finalizePromise
            )

            window.history.replaceState({}, document.title, window.location.pathname)
        }, {
            stateFunc: setBusy,
            operation: 'Finalizing Sign-Up',
        })
    }, [])

    if (student) {
        return (
            <Redirect to={{
                pathname: '/login',
            }}/>
        )
    }

    if (busy) {
        return <Loading/>
    }

    showMessage('Whoops! There was an error.', 'error')
    return <Redirect to="/" />
}
