// @ts-nocheck comment
/* eslint-disable no-restricted-globals */
import '../../styles/style.css'

import Fab from '@material-ui/core/Fab'
import * as React from 'react'
import { MobileView } from 'react-device-detect'

interface Props {
  Icon?: any
}

const FAB: React.FC<Props> = ({ Icon, children }) => {

    const handleLockScreenRotation = async () => {
        if (document && document.querySelector('body')?.requestFullscreen) {
            await document.querySelector('body')?.requestFullscreen() 
        } else if (document.querySelector('body')?.webkitRequestFullScreen) {
            await document.querySelector('#container')?.webkitRequestFullScreen() 
        }
        if (hasRotation) {
            try {
                await screen.orientation.lock('landscape')
            } catch (error) {
                // alert(error)
            }
        } else if (screen.orientation.type !== 'portrait-primary') {
            try {
                await screen.orientation.unlock()
                exitFullScreen()
            } catch (error) {
                // alert(error)
            }
        }
        setHasRotation(!hasRotation)
    }
        
    const [hasRotation, setHasRotation] = React.useState(false)

    async function exitFullScreen() {
        const { document } = window
        if (!document) return
        if (document.exitFullscreen) {
            await document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
            await document.webkitExitFullscreen()
        } else if (document.mozCancelFullScreen) {
            await document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
            await document.msExitFullscreen()
        }
    }

    return (
        <div>
            
            <MobileView>
                <Fab color="primary" aria-label="Rotate Screen" onClick={() => handleLockScreenRotation()}>
                    <Icon />
                    {children}
                </Fab>
            </MobileView>
            
        </div>
    )
}
export default FAB
