import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import { useHistory } from 'react-router-dom'

import Logo from '../../assets/logoText.png'
import { StudentDrawerRoutes } from '../../utils/routes'

interface Props { }

export const SideBar: React.FC<Props> = () => {

    const history = useHistory()

    return (
        <>
            <Box justifyContent="center" pl={2}>
                <img style={{ width: 60, height: 60 }} src={Logo} alt="Geekster Logo" />
            </Box>
            <List>
                {StudentDrawerRoutes.map((item) => (
                    <ListItem button key={item.name} onClick={() => history.push(item.path)}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List>
            <Divider />
        </>
    )
}
