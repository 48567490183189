import { API } from 'aws-amplify'

import {
    FileType, LibraryFilter, Question,
    StudentQuizType
} from '../types'

export const getStudentQuizById = async (quizId: string): Promise<StudentQuizType> => {
    const quiz = await API.get('api', `/quizzes/student/${quizId}`, {})
    return quiz
}
export const listStudentQuizzes = async (): Promise<StudentQuizType[]> => {
    const quizzes = await API.get('api', '/quizzes/student', {})
    return quizzes
}
export const listPublicCommunityQuizzes = async (): Promise<StudentQuizType[]> => {
    const publicQuizzes = await API.get('api', '/quizzes/student/public', {})
    return publicQuizzes
}

export const createInitiallyCommunityQuiz = async (title: string, topics: string[],
    categories: string[], grades: number[], isPublic: boolean,
    onlyOrderedResponse: boolean): Promise<StudentQuizType> => {
    return API.post('api', '/quizzes/student', {
        body: {
            title, topics, categories, grades, isPublic, onlyOrderedResponse
        }
    })
}

export const finishCreatingCommunityQuiz = async (quizId: string, description: string,
    questions: Question[], file: FileType | undefined): Promise<StudentQuizType> => {
    return API.put('api', `/quizzes/student/${quizId}`, {
        body: {
            description, questions, file
        }
    })
}

export const updateCommunityQuizQuestions = async (quizId: string, questions: Question[]):
    Promise<StudentQuizType> => {
    return API.put('api', `/quizzes/student/${quizId}`, {
        body: {
            questions
        }
    })
}

export const addQuestionToCommunityQuiz = async (
    quizId: string, title: string, explanation: string,
    responses: any[], vimeoVideoId?: string, youtubeVideoId?: string, picture?: FileType | undefined
): Promise<StudentQuizType> => {
    return API.put('api', `/quizzes/student/question/${quizId}`, {
        body: {
            title, explanation, responses, vimeoVideoId, youtubeVideoId, picture
        }
    })
}
export const updateCommunityQuiz = async (quizId: string, title: string, description: string,
    file: FileType | undefined, categories: string[], topics: string[],
    grades: number[], questions: Question[], isPublic: boolean,
    onlyOrderedResponse: boolean): Promise<StudentQuizType> => {
    return API.put('api', `/quizzes/student/${quizId}`, {
        body: {
            title, description, categories, file, topics, grades, questions, isPublic, onlyOrderedResponse
        }
    })
}

export const deleteCommunityQuiz = async (quizId: string) => {
    return API.del('api', `/quizzes/student/${quizId}`, {})
}
export const filterCommunityQuizzes = async (filterParams: LibraryFilter): Promise<StudentQuizType[]> => {
    const quizzes = await API.post('api', '/quizzes/student/filter', {
        body: filterParams
    })
    return quizzes
}

export const getCommunityQuizById = async (quizId: string): Promise<StudentQuizType> => {
    const quiz = await API.get('api', `/quizzes/student/${quizId}`, {})
    return quiz
}
