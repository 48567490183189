import Button, { ButtonProps } from '@material-ui/core/Button'
import React from 'react'

import { StyledLink } from './StyledLink'

interface Props extends ButtonProps {
    text: string;
    to: { pathname: string; state: any } | string;
}

export const LinkButton: React.FC<Props> = ({
    text, to, variant, ...rest
}) => (
    <StyledLink to={to}>
        <Button
            style={{
                color: '#fff',
                backgroundColor: '#3f51b5',
                textTransform: 'uppercase'
            }}
            {...rest}
            variant={variant || 'contained'}>{text}
        </Button>
    </StyledLink>
)
