import React from 'react'

import HomeImage from '../../assets/homeImage.png'

export function BottomBackgroundImage() {
    return (
        <div style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0
        }}>
            <img
                style={{
                    position: 'relative',
                    maxWidth: '23vw',
                    height: 'auto',
                    marginLeft: '74%',
                    marginBottom: -5
                }}
                src={HomeImage}
                alt="People and numbers" />
        </div>
    )
}
