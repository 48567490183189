import { API } from 'aws-amplify'

import { LibraryFilter, VideoType, VimeoVideoType } from '../types'

export const listVideos = async (): Promise<VideoType[]> => {
    const videos = await API.get('api', '/videos', {})
    return videos
}

export const filterVideos = async (filterParams: LibraryFilter): Promise<VideoType[]> => {
    const videos = await API.post('api', '/videos/filter', {
        body: filterParams
    })
    return videos
}

export const validateVimeoVideo = async (id: string): Promise<VimeoVideoType | undefined> => {
    return API.get('api', `/videos/vimeo/${id}`, {})
}
