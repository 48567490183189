import { ThemeProvider } from '@material-ui/core'
import * as React from 'react'

import { buttonTheme } from '../../styles/TableStyles'
// import { buttonTheme } from '../../utils/styles'
import CreateButton from './CreateButton'
import DeleteButton from './DeleteButton'

interface Props {
    createButtonText?: string,
    onClickCreate?: () => void,
    inviteButtonText?: string,
    onClickInvite?: () => void,
    onClickDelete?: false | (() => void),
    deleteButtonText?: string,
    disableButtons?: boolean,
    disableCreate?: boolean
}

const FormButtons: React.FC<Props> = ({
    createButtonText,
    inviteButtonText,
    onClickCreate,
    onClickInvite,
    onClickDelete,
    deleteButtonText,
    disableButtons,
    disableCreate
}) => {
    return (
        <ThemeProvider theme={buttonTheme}>
            {onClickCreate && createButtonText && (
                <CreateButton
                    createButtonText={createButtonText}
                    disableButtons={disableButtons || false}
                    disableCreate={disableCreate || false}
                    onClickCreate={onClickCreate} />
            )}
            {onClickInvite && inviteButtonText && (
                <CreateButton
                    createButtonText={inviteButtonText}
                    disableButtons={disableButtons || false}
                    disableCreate={disableCreate || false}
                    onClickCreate={onClickInvite} />
            )}
            {onClickDelete && (
                <DeleteButton
                    deleteButtonText={deleteButtonText || 'Annuller'}
                    onClickDelete={onClickDelete}
                    disableButtons={disableButtons || false} />
            )}
        </ThemeProvider>
    )
}
export default FormButtons
