import { Auth, Hub } from 'aws-amplify'
import React from 'react'

import { Severity } from '../../components/molecules/NotificationPopUp'
import Config from '../../config'
import { DelegatedCognitoLogin } from '../types'

const prefix = 'CognitoIdentityServiceProvider'

export const handleSignInError = (errorMessage: string, renderError: (message: string, severity: Severity) => void) => {
    switch (errorMessage) {
        case 'Incorrect username or password.':
            renderError('Du har indtastet et forkert brugernavn eller kodeord.', 'error')
            break
        default:
            renderError(`${errorMessage}`, 'error')
    }
}

export const restoreCognitoSession = (creds: DelegatedCognitoLogin) => {
    localStorage.setItem(`${prefix}.${Config.Auth.userPoolWebClientId}.${creds.Username}.idToken`,
        creds.IdToken)
    localStorage.setItem(`${prefix}.${Config.Auth.userPoolWebClientId}.${creds.Username}.refreshToken`,
        creds.RefreshToken)
    localStorage.setItem(`${prefix}.${Config.Auth.userPoolWebClientId}.${creds.Username}.accessToken`,
        creds.AccessToken)
    localStorage.setItem(`${prefix}.${Config.Auth.userPoolWebClientId}.LastAuthUser`,
        creds.Username)
}

export const useCognito = (opts?: { onChange?(signedIn: boolean): void }) => {
    const [signedIn, _setSignedIn] = React.useState<boolean>(false)

    const setSignedIn = (v: boolean) => {
        if (opts?.onChange) {
            opts.onChange(v)
        }
        _setSignedIn(v)
    }

    const authListener = React.useCallback(({ payload: { event } }) => {

        // eslint-disable-next-line default-case
        switch (event) {
            case 'signIn':
                setSignedIn(true)
                break
            case 'signOut':
                setSignedIn(false)
                break
        }
    }, [])

    const checkLoginStatus = async () => {
        try {
            await Auth.currentAuthenticatedUser()
            Hub.dispatch('auth', { event: 'signIn' })
        } catch (error) {
            Hub.dispatch('auth', { event: 'signOut' })
        }
    }

    React.useEffect(() => {
        Hub.listen('auth', authListener)

        checkLoginStatus().then()

        return () => Hub.remove('auth', authListener)
    }, [])

    return { signedIn }
}
