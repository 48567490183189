import {
    FormControlLabel, Switch, Typography
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import * as React from 'react'

import { Loading } from '../../content/Loading'
import { getMyDetails, updateSetting } from '../../utils/Services/student-services'
import { StudentType } from '../../utils/types'
import { wrap } from '../../utils/wrapers'
import { CustomButton } from '../atoms/CustomButton'

interface Props {
    student: StudentType

}

export const ConfettiForm: React.FC<Props> = ({ student, }: Props) => {
    React.useEffect(() => {
        (async () => {
            if (!localStorage.getItem('confetti')) {
                const { confetti } = await getMyDetails() || false
                setConfetti(confetti ?? false)
                localStorage.setItem('confetti', JSON.stringify(confetti ?? false))
            }

        })()
    }, [])

    const [busy, setBusy] = React.useState(false)
    const [confetti, setConfetti] = React.useState<boolean>(JSON.parse(localStorage.getItem('confetti') as string))
    const [hasAudio, setHasAudio] = React.useState<boolean>(
        JSON.parse(
            localStorage.getItem('hasAudio') !== 'undefined'
                ? localStorage.getItem('hasAudio') as string : 'true'
        )
    )

    const updateSettings = async () => {
        await wrap(async () => {
            try {
                await updateSetting(student.id, { confetti, hasAudio })
                localStorage.setItem('confetti', JSON.stringify(confetti))
                localStorage.setItem('hasAudio', JSON.stringify(hasAudio))
            } catch (error) {
                // console.log(error)
            }
        }, {
            operation: 'Vellykket opdatering',
            showSuccess: true,
            stateFunc: setBusy
        })
    }

    return (
        <Box width={300} p={2} mb={5} m="auto">
            <Typography variant="h6">Konfetti</Typography>
            {busy && <Loading />}

            <FormControlLabel
                control={(
                    <Switch
                        style={{
                            color: '#3f51b5',
                            textTransform: 'uppercase'
                        }}
                        checked={confetti}
                        onChange={() => {
                            setConfetti(!confetti)
                        }}
                        color="primary" />
                )}
                label={confetti ? 'Tændt' : 'Slukket'} />

            <Typography variant="h6">Lyd-effekter</Typography>
            {busy && <Loading />}

            <FormControlLabel
                control={(
                    <Switch
                        style={{
                            color: '#3f51b5',
                            textTransform: 'uppercase'
                        }}
                        checked={hasAudio}
                        onChange={() => {
                            setHasAudio(!hasAudio)
                        }}
                        color="primary" />
                )}
                label={hasAudio ? 'Tændt' : 'Slukket'} />

            <CustomButton
                onClick={updateSettings}
                variant="contained"
                loading={busy}
                color="primary"
                fullWidth>
                opdatere indstillinger
            </CustomButton>
        </Box>
    )
}
