import { Button, Container, Typography } from '@material-ui/core'
import React from 'react'

import Image from '../../assets/LogoGeekster.png'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { homeworkStyles } from '../../styles/HomeworkStyle'

interface Props {
  finishQuiz: () => void
}

export const SubmitQuizContainer: React.FC<Props> = ({
    finishQuiz
}) => {
    const classes: any = buttonStyles()
    const classesHomework = homeworkStyles()
    
    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
            maxWidth="md"
        >
            
            <img className={classesHomework.image} style={{ width: '27%', height: 'auto' }} src={Image} alt="Glasses" />
            <Typography className={classesHomework.text} variant="h4">
                Godt klaret!!
            </Typography>
            <Typography className={classesHomework.text} variant="h4"> Du har besvaret alle spørgsmålene.
            </Typography>
            <Button className={classes.uploadButton} onClick={finishQuiz}>Send din besvarelse
            </Button>
        </Container>
    )
}
