import { API } from 'aws-amplify'

import {
    AssignedHomeworkType,
    CreateUniLoginAuthIntentType,
    CreateUniLoginSignUpIntentType,
    DelegatedCognitoLogin,
    FinalizeUniLoginAuthIntentType,
    FinalizeUniLoginSignUpIntentType,
    FulfillSignUpIntentDetailsType,
    SignUpIntentValidationType,
    StudentType,
    UniLoginAuthIntentType
} from '../types'

const AnonIdentityKey = 'anon-identity'

export const createAnonymousIdentity = async (homeworkId: string) => {
    const ids = JSON.parse(localStorage.getItem(AnonIdentityKey) || '{}')
    const localId = ids[homeworkId] as StudentType

    if (localId) {
        return localId
    }

    const newId = await API.post('openapi', '/anon', {
        body: {
            homeworkId
        }
    }) as StudentType

    ids[homeworkId] = newId
    localStorage.setItem(AnonIdentityKey, JSON.stringify(ids))

    return newId
}

export const getHomeworks = async (): Promise<AssignedHomeworkType[]> => {
    return API.get('api', '/student/homeworks', {})
}

export const getAssignedHomework = async (homeworkId: string) => await API.get(
    'api', `/student/homeworks/${homeworkId}`, {}
) as AssignedHomeworkType

export const getIsPublicHomework = async (homeworkId: string) => await API.get(
    'openapi', `/homeworks/${homeworkId}/public/`, {}
) as boolean

export const getAnonymousAssignedHomework = async (anonId: string, homeworkId: string) => await API.get(
    'openapi', `/anon/${anonId}/homeworks/${homeworkId}`, {}
) as AssignedHomeworkType

export const updateAssignedHomework = async (homeworkId: string, params: AssignedHomeworkType):
    Promise<AssignedHomeworkType | undefined> => {
    return API.put('api', `/student/homeworks/${homeworkId}`, {
        body: params
    })
}

export const getMyDetails = async (): Promise<StudentType> => {
    return API.get('api', '/students/me', {})
}

export const updateSetting = async (student: string, params: { [key: string]: boolean }): Promise<StudentType> => {
    return await API.put('api', `/student/${student}`, { body: params }) as StudentType
}

export const validateSignUpLink = async (inviteId: string) => {
    return await API.get('openapi', `/student/invite/${inviteId}`, {}) as SignUpIntentValidationType
}

export const fulfillSignUpLink = async (inviteId: string, details: FulfillSignUpIntentDetailsType) => {
    return await API.post('openapi', `/student/invite/${inviteId}`, {
        body: details
    }) as SignUpIntentValidationType
}

export const initiateSignUpStudentWithUniLogin = async (
    inviteId: string,
    details: FulfillSignUpIntentDetailsType,
    returnUrl: string
) => {
    const body: CreateUniLoginSignUpIntentType = {
        returnUrl,
        payload: {
            signUpIntentId: inviteId,
            request: details
        },
        group: 'student'
    }

    return await API.post('openapi', '/unilogin/signup', {
        body
    }) as UniLoginAuthIntentType
}

export const finalizeSignUpStudentWithUniLogin = async (params: FinalizeUniLoginSignUpIntentType) => {
    return await API.put('openapi', '/unilogin/signup', {
        body: params
    }) as StudentType
}

export const initiateLogInStudentWithUniLogin = async (returnUrl: string) => {
    const body: CreateUniLoginAuthIntentType = {
        returnUrl,
        group: 'student'
    }

    return await API.post('openapi', '/unilogin/login', {
        body
    }) as UniLoginAuthIntentType
}

export const finalizeLogInStudentWithUniLogin = async (params: FinalizeUniLoginAuthIntentType) => {
    return await API.put('openapi', '/unilogin/login', {
        body: params
    }) as DelegatedCognitoLogin
}
