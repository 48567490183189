import '../../styles/correct.css'

import {
    createStyles, makeStyles, Switch, Typography
} from '@material-ui/core'
import * as React from 'react'

const useStyles = makeStyles(() => createStyles({
    toggle: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'
    }
}),)

interface Props {
    checked: boolean,
    setChecked: (checked: boolean) => void,
    falseText: string,
    trueText: string
}

const SwitchWithSideText: React.FC<Props> = ({
    checked, setChecked, falseText, trueText
}) => {
    const classes = useStyles()

    return (
        <div className={classes.toggle}>
            <Typography>{falseText}</Typography>
            <Switch
                style={{
                    flex: 1,
                    color: '#3f51b5'
                }}
                checked={checked}
                onChange={
                    (event) => setChecked(event.target.checked as boolean)
                }
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }} />
            <Typography>{trueText}</Typography>
        </div>
    )
}
export default SwitchWithSideText
