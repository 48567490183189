export type Group = 'admin' | 'teacher' | 'student'

export type StudentQuizStatus = 'approved' | 'rejected' | 'updated' | 'unpublished'

export type DisapprovedStudentQuizAttributes = keyof ApprovableStudentQuizAttributes

export type UpdateStudentQuizType = ApprovableStudentQuizAttributes & {
    onlyOrderedResponse?: boolean;
    homeworks?: string[];
    feedbackNote: string;
    status?: StudentQuizStatus;
    isPublic?: boolean;
    notApprovedFields: DisapprovedStudentQuizAttributes[];
    category: string;
}

export type ApprovableStudentQuizAttributes = {
    title: string;
    categories?: string[];
    topics: string[];
    grades?: number[];
    file?: FileType;
    description?: string;
    questions?: Question[];
}

export type StudentQuizType = UpdateStudentQuizType & {
    id: string;
    ownerId: string;
    filterName?: string;
    lastUpdated: string;
    createdAt: string;
    categories?: string[];
    tags: string[];
}

export type UploadedFile = {
    key: string;
    uri: string;
}

export type VimeoVideoType = {
    id: string;
    thumbnailUri: string;
    title: string;
}

export type LibraryFilter = {
    grades?: number[];
    categories?: string[];
    topics?: string[];
    searchTextInput?: string;
    public?: boolean;
}

export type UserId = string

export type FileType = {
    name: string;
    key: string;
    uri: string;
}

export type UpdateVideoType = {
    vimeoVideoId: string;
    title: string;
    category: string;
    fact?: string;
    description?: string;
    tags?: string[];
    grades?: number[];
    isPublic: boolean;
    supplementUri?: string[];
    mainFile?: FileType;
    resultsFile?: FileType;
}

export type VideoType = UpdateVideoType & {
    id: string;
    videoUri: string;
    thumbnailUri: string;
}

export type UpdateSoundType = {
    soundFile: FileType | null;
}

export type SoundType = UpdateSoundType & {
    id: string;
    createdAt?: string;
    lastUpdated?: string;
}

export type RandomSoundsType = {
    positiveFeedback: SoundType,
    negativeFeedback: SoundType
}

export type StudentType = PersonType & {
    uniLoginUsername?: string;
    username: string;
    createdAt?: string;
    teacherId?: string;
    schoolClasses?: string[];
    schoolId?: string;
    confetti: boolean;
    hasAudio: boolean;
}

export type TeacherType = PersonType & {
    email: string;
    school: string;
    schoolId?: string;
    createdAt: string;
    expireSubscription: string;
    code: string;
    award: string;
    isActive?: boolean;
    schoolClasses: string[];
    students: string[];
}

export type UpdateHomeworkType = {
    name: string;
    expirationDate: string;
    url?: string;
    videos: string[];
    studentsNeedToLogIn: boolean;
    classIds: string[];
}

export type PersonType = {
    id: string;
    firstName: string;
    lastName: string;
}

export type HomeworkType = UpdateHomeworkType & {
    id: string;
    teacher: PersonType;
}

export type CreateAdminType = {
    email: string;
    password: string;
}

export type AdminType = {
    email: string;
    createdAt: Date;
}

export type UpdatePriceType = {
    price: number;
    amountOfMonths: number;
}

export type PriceType = UpdatePriceType & {
    id: string;
}

export type UpdateCampaignType = {
    code: string;
    amount: number;
}

export type CampaignType = UpdateCampaignType & {
    id: string;
}

export type UpdateSchoolType = {
    uniLogId: number;
    name: string;
    expires: Date;
}

export type SchoolType = UpdateSchoolType & {
    id: string;
}

export type UpdateQuizLibraryType = {
    title: string;
    category: string;
    tags: string[];
    grades?: number[];
    file?: FileType;
    description?: string;
    onlyOrderedResponse?: boolean;
    isPublic?: boolean;
    questions?: Question[];
}

export type QuizLibraryType = UpdateQuizLibraryType & {
    id: string;
}

export type ClassType = {
    id: string;
    title: string;
    description: string;
    teachers?: string[];
    students?: string[];
    homeworks?: string[];
}

export type Question = {
    questionIndex: number;
    title: string;
    explanation: string;
    responses: string[];
    picture?: FileType;
    vimeoVideoId?: string;
    youtubeVideoId?: string;
    videoUri?: string;
    thumbnailUri?: string;
    openQuestion?: boolean;
}

export type FetchedStudentNames = {
    firstName: string;
    lastName: string;
}

export type AssignedHomeworkType = {
    id: string;
    status: AssignedHomeworkStatusType;
    name: string;
    expirationDate: string;
    isExpired: boolean;
    url?: string;
    videos: VideoType[];
    quizzes: AssignedQuizType[];
    teacher: PersonType;
}

export enum AssignedHomeworkStatusType {
    NotStarted = 'not-started',
    Started = 'started',
    Finished = 'finished'
}

export type AnswerType = {
    questionIndex: number;
    answer: string;
    correct: boolean;
    sharedUrl?: string;
}

export type SubmitQuizAnswerType = {
    quizId: string;
    homeworkId: string;
    questionIndex: number;
    answer: string;
}

export type AssignedQuestionType = {
    title: string;
    givenAnswer?: AnswerType;
    explanation: string;
    picture?: FileType;
    vimeoVideoId?: string;
    youtubeVideoId?: string;
    videoUri?: string;
    thumbnailUri?: string;
    openQuestion?: boolean;
}

export type AssignedQuizType = {
    id: string;
    title: string;
    category: string;
    topics: string[];
    file?: FileType;
    description?: string;
    onlyOrderedResponse: boolean;
    isFinished: boolean;
    questions: AssignedQuestionType[];
    lastUpdated?: string;
}

export type SignUpIntentValidationType = {
    person: PersonType;
    expiresAt: string;
}

export type FulfillSignUpIntentDetailsType = {
    username: string;
    firstName: string;
    lastName: string;
    password: string;
}

export type QuizResultType = {
    quizId: string;
    homeworkId: string;
    answers: AnswerType[];
    teacherId: string;
    studentId: string;
    id: string;
    createdAt: string;
    isFinished: boolean;
    lastUpdatedAt: string;
}

export type CreateUniLoginAuthIntentType = {
    returnUrl: string;
    group: 'student';
}

export type CreateUniLoginSignUpIntentType = CreateUniLoginAuthIntentType & {
    payload: {
        request: FulfillSignUpIntentDetailsType;
        signUpIntentId: string;
    }
}

export type UniLoginAuthIntentType = {
    clientUri: string;
}

export type FinalizeUniLoginAuthIntentType = {
    group: 'student';
    uniLoginUsername: string;
    uniLoginTimestamp: string;
    uniLoginAuth: string;
}

export type FinalizeUniLoginSignUpIntentType = FinalizeUniLoginAuthIntentType & {
    signUpIntentId: string;
}

export type DelegatedCognitoLogin = {
    Username: string,
    AccessToken: string,
    IdToken: string,
    RefreshToken: string
}
