import { Paper, TableContainer } from '@material-ui/core'
import * as React from 'react'
import { useAsync } from 'react-async-hook'

import { Loading } from '../../content/Loading'
import {
    deleteCommunityQuiz,
    listStudentQuizzes,
    updateCommunityQuiz
} from '../../utils/Services/community-quiz-service'
import { useTableStyles } from '../../utils/styles'
import { FileType, Question } from '../../utils/types'
import ConfirmDialog from '../atoms/ConfirmDialog'
import FormButtons from '../atoms/FormButtons'
import UpdateCommunityQuizComponent from './UpdateCommunityQuizComponent'

interface Props {
    selected: string,
    isSelected: (item: any) => void,
    setFinishCreatingId: (quizId: string) => void,
    onQuizUpdate: () => void
}

const UpdateCommunityQuizScreen: React.FC<Props> = ({
    selected, isSelected, setFinishCreatingId, onQuizUpdate
}) => {
    const tableClasses = useTableStyles()

    const [title, setTitle] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')
    const [file, setFile] = React.useState<FileType>()
    const [topics, setTopics] = React.useState<string[]>([])
    const [category, setCategory] = React.useState<string[]>([])
    const [selectedGrades, setSelectedGrades] = React.useState<string[]>([])
    const [openQuiz, setOpenQuiz] = React.useState<boolean>(false)
    const [publicQuiz, setPublicQuiz] = React.useState<boolean>(true)
    const [questions, setQuestions] = React.useState<Question[]>([])
    const [acceptedTaC, setAcceptedTaC] = React.useState<boolean>(false)
    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)

    const [loading, setLoading] = React.useState<boolean>(false)
    const fetchStudentQuizzes = useAsync(listStudentQuizzes, [])
    const onDelete = async () => {
        setConfirmDelete(false)
        await deleteCommunityQuiz(selected)
        // eslint-disable-next-line no-restricted-globals
        location.reload()
        setLoading(true)
        fetchStudentQuizzes.execute()
        setLoading(false)
        isSelected(null)
    }

    const onUpdate = async () => {
        setLoading(true)
        const fileredGrades = selectedGrades.flatMap((grade) => {
            if (!Number.isNaN(parseInt(grade.slice(0, -8), 10))) return parseInt(grade.slice(0, -8), 10)
            return []
        })
        await updateCommunityQuiz(selected, title, description,
            file, category, topics, fileredGrades, questions, publicQuiz, !openQuiz)
        onQuizUpdate()
        setLoading(false)
        isSelected(null)
    }
    if (loading) {
        return <Loading />
    }
    return (
        <>
            <TableContainer className={tableClasses.table} component={Paper}>
                <UpdateCommunityQuizComponent
                    setFinishCreatingId={setFinishCreatingId}
                    selectedId={selected}
                    isSelected={isSelected}
                    headerText="Geeksters Quiz-Værktøj"
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    file={file}
                    setFile={setFile}
                    topics={topics}
                    setTopics={setTopics}
                    category={category}
                    setCategory={setCategory}
                    questions={questions}
                    setQuestions={setQuestions}
                    acceptedTaC={acceptedTaC}
                    setAcceptedTaC={setAcceptedTaC}
                    openQuiz={openQuiz}
                    setOpenQuiz={setOpenQuiz}
                    publicQuiz={publicQuiz}
                    setPublicQuiz={setPublicQuiz}
                    selectedGrades={selectedGrades}
                    setSelectedGrades={setSelectedGrades} />
            </TableContainer>
            <FormButtons
                createButtonText="Gem"
                onClickCreate={onUpdate}
                onClickDelete={() => { setConfirmDelete(true); fetchStudentQuizzes.execute() }}
                deleteButtonText="Slet quiz"
                disableCreate={!acceptedTaC}
                disableButtons={loading} />
            <ConfirmDialog
                text={`Er du sikker på at du vil slette denne quiz "${title}"?`}
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={onDelete} />
        </>
    )
}
export default UpdateCommunityQuizScreen
