import {
    Button,
    Container,
    createTheme,
    Grid,
    ThemeProvider,
} from '@material-ui/core'
import { lightBlue, red } from '@material-ui/core/colors'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Loading } from '../../content/Loading'
import { buttonStyles } from '../../styles/ButtonsStyles'
// import { CommunityQuizType, FileType, Question } from '../../types/types'
import {
    gradeLevels, indskolingGradeLevels, mellemtrinGradeLevels, possibleQuizCategories,
    possibleQuizTopics, udskolingGradeLevels
} from '../../utils/globalData'
import { getGradesByKeyword } from '../../utils/helpers'
import { getCommunityQuizById } from '../../utils/Services/community-quiz-service'
import { uploadFile } from '../../utils/Services/file-service'
import { FileType, Question, StudentQuizType } from '../../utils/types'
import { BasicMultipleSelect } from '../atoms/BasicMultipleSelect'
import GoBackHeader from '../atoms/GoBackHeader'
import { QuestionModal } from '../atoms/QuestionModal'
import { ReasonModal } from '../atoms/ReasonModal'
import SwitchWithSideText from '../atoms/SwitchWithSideText'
import TaCCheck from '../atoms/TaCCheck'
import { FileUpload } from '../molecules/FileUpload'
import { TextInputTemplate } from '../molecules/TextInputTemplate'

interface Props {
    setFinishCreatingId: (quizId: string) => void,
    headerText: string,
    selectedId: string,
    isSelected: (item: any) => void,
    title: string,
    setTitle: (title: string) => void,
    description: string,
    setDescription: (description: string) => void,
    file: FileType | undefined,
    setFile: (file: FileType | undefined) => void,
    topics: string[],
    setTopics: (topics: string[]) => void,
    category: string[],
    setCategory: (category: string[]) => void,
    questions: Question[],
    setQuestions: (questions: Question[]) => void,
    acceptedTaC: boolean,
    setAcceptedTaC: (acceptedTaC: boolean) => void,
    openQuiz: boolean,
    setOpenQuiz: (open: boolean) => void,
    publicQuiz: boolean
    setPublicQuiz: (isPublic: boolean) => void,
    selectedGrades: string[],
    setSelectedGrades: (selectedGrades: string[]) => void
}

const theme = createTheme({
    palette: {
        primary: lightBlue,
        secondary: red,
    },
})

const UpdateCommunityQuizComponent: React.FC<Props> = ({
    headerText, selectedId, isSelected, title, setTitle,
    description, setDescription, category, setCategory, file, setFile, topics, setTopics,
    questions, setQuestions, acceptedTaC, setAcceptedTaC, setFinishCreatingId, setPublicQuiz,
    setOpenQuiz, openQuiz, publicQuiz, selectedGrades, setSelectedGrades
}) => {
    const history = useHistory()
    const classes = buttonStyles()
    const [selected, setSelected] = React.useState<StudentQuizType>()
    const [loading, setLoading] = React.useState(false)
    const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState<number>()
    const [openQuestionModal, setOpenQuestionModal] = React.useState(false)
    const [openReasonModal, setOpenReasonModal] = React.useState(false)

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            const selectedQuiz = await getCommunityQuizById(selectedId)
            if (selectedQuiz) {
                setTitle(selectedQuiz.title as string)
                setDescription(selectedQuiz.description as string)
                setCategory(selectedQuiz.categories || [])
                setTopics(selectedQuiz.topics || [])
                setOpenQuiz(!selectedQuiz.onlyOrderedResponse || false)
                setPublicQuiz(selectedQuiz.isPublic as boolean)
                setQuestions(selectedQuiz.questions || [])
                setSelectedGrades(selectedQuiz.grades ? selectedQuiz.grades.map((grade) => `${grade}. klasse`) : [])
                if (selectedQuiz.status !== 'rejected') setAcceptedTaC(true)
            }
            setSelected(selectedQuiz)
            setLoading(false)
        })()
    }, [])
    const closeQuestionModal = () => {
        setSelectedQuestionIndex(undefined)
        setOpenQuestionModal(false)
    }
    const handleSelectQuestion = (index: number) => {
        setSelectedQuestionIndex(index)
        setOpenQuestionModal(true)
    }
    const handleGradesChange = (event: any) => {
        if (event.target.value.length === 0) setSelectedGrades([])
        else {
            let newGrades = event.target.value as string[]

            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'indskoling', indskolingGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'mellemtrin', mellemtrinGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'udskoling', udskolingGradeLevels, selectedGrades)

            setSelectedGrades(newGrades)
        }
    }
    const handleUpdateQuestions = () => {
        setFinishCreatingId(selectedId)
    }
    if (loading) {
        return <Loading />
    }
    return (
        <ThemeProvider theme={theme}>
            {selected && (
                <>
                    <GoBackHeader headerText={headerText} onClick={() => isSelected(null)} />
                    <Grid container>
                        <Grid container style={{ marginBottom: 20 }}>
                            <Grid item xs={12} sm={4} className={classes.columnContainer}>
                                <TextInputTemplate
                                    error={selected.notApprovedFields.indexOf('title') > -1}
                                    helperText="Ikke godkendt felt"
                                    value={title}
                                    onChange={setTitle}
                                    label="Quiz navn" />
                                <TextInputTemplate
                                    error={selected.notApprovedFields.indexOf('description') > -1}
                                    helperText="Ikke godkendt felt"
                                    value={description}
                                    onChange={setDescription}
                                    label="Quiz beskrivelse" />
                                <SwitchWithSideText
                                    falseText="Lukket"
                                    trueText="Åben"
                                    checked={openQuiz}
                                    setChecked={setOpenQuiz} />
                                <SwitchWithSideText
                                    falseText="Privat"
                                    trueText="Offentlig"
                                    checked={publicQuiz}
                                    setChecked={setPublicQuiz} />
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.columnContainer}>
                                <Button
                                    style={{
                                        color: '#fff',
                                        backgroundColor: '#3f51b5',
                                        textTransform: 'uppercase'
                                    }}
                                    onClick={() => history.push('/quiz/student', { id: selectedId })}>
                                    Se quiz
                                </Button>
                                <FileUpload
                                    file={file}
                                    buttonText="Upload"
                                    underText="Her kan du uploade opgaven som pdf-fil"
                                    uploadFunc={uploadFile}
                                    allowedType="document"
                                    onChange={setFile} />
                                {selected.status === 'rejected' && (
                                    <Button
                                        style={{ textTransform: 'none' }}
                                        onClick={() => setOpenReasonModal(true)}
                                        variant="contained"
                                        color="secondary">
                                        Hvorfor bliver min quiz afvist?
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.columnContainer}>
                                <BasicMultipleSelect
                                    small
                                    label="Kategori"
                                    value={category}
                                    setValue={setCategory}
                                    possibleValues={possibleQuizCategories} />
                                <BasicMultipleSelect
                                    small
                                    label="Emner"
                                    value={topics}
                                    setValue={setTopics}
                                    possibleValues={possibleQuizTopics} />
                                <BasicMultipleSelect
                                    small
                                    label="Klassetrin"
                                    value={selectedGrades}
                                    setValue={() => true}
                                    onChange={handleGradesChange}
                                    possibleValues={gradeLevels} />
                            </Grid>
                        </Grid>
                        <Container style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            {selected.questions && questions.map((q, index) => (
                                <Button
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${q.title}${index}`}
                                    className={classes.editQuestionButton}
                                    onClick={() => handleSelectQuestion(index)}>
                                    <p>{q.title}</p>
                                </Button>
                            ))}
                            <Button
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#3f51b5',
                                    textTransform: 'uppercase',
                                    marginLeft: 'auto',
                                    maxHeight: '70%'
                                }}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleUpdateQuestions}>
                                Opdater spørgsmål
                            </Button>
                        </Container>
                        {selected.status === 'rejected' && (
                            <TaCCheck acceptedTaC={acceptedTaC} setAcceptedTaC={setAcceptedTaC} />
                        )}
                    </Grid>
                </>
            )}
            {selectedQuestionIndex !== undefined && questions.length > 0 && (
                <QuestionModal
                    setLoading={setLoading}
                    questions={questions}
                    setQuestions={setQuestions}
                    quizId={selectedId}
                    indexOfQuiz={selectedQuestionIndex}
                    openModal={openQuestionModal}
                    setOpenModal={closeQuestionModal}
                    type="edit"
                    title={questions[selectedQuestionIndex].title}
                    explanation={questions[selectedQuestionIndex].explanation}
                    vimeoVideoId={questions[selectedQuestionIndex].vimeoVideoId || ''}
                    youtubeVideoId={questions[selectedQuestionIndex].youtubeVideoId || ''}
                    pictureFile={questions[selectedQuestionIndex].picture || undefined}
                    responses={questions[selectedQuestionIndex].responses} />
            )}
            <ReasonModal
                openReasonModal={openReasonModal}
                setOpenReasonModal={setOpenReasonModal}
                selected={selected} />
        </ThemeProvider>
    )
}
export default UpdateCommunityQuizComponent
