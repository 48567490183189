import {
    Button
} from '@material-ui/core'
import * as React from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'

interface Props {
    deleteButtonText: string,
    onClickDelete: () => void,
    disableButtons: boolean
}

const DeleteButton: React.FC<Props> = ({
    onClickDelete,
    deleteButtonText,
    disableButtons
}) => {
    const buttonClasses = buttonStyles()
    return (
        <Button
            style={{
                backgroundColor: '#ff1744',
                color: '#fff',
                textTransform: 'uppercase'
            }}
            className={buttonClasses.otherButtons}
            variant="contained"
            color="secondary"
            onClick={onClickDelete}
            disabled={disableButtons}>
            {deleteButtonText}
        </Button>
    )
}
export default DeleteButton
