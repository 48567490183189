/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import Button,
{ ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as React from 'react'

interface Props extends ButtonProps {
  children: string;
  loading?: boolean;
}

export const CustomButton: React.FC<Props> = ({ children, loading, ...rest }) => (
  <Button
    style={{ color: '#fff', backgroundColor: '#3f51b5', textTransform: 'uppercase' }}
    {...rest}>{loading ? <CircularProgress size={24} color="inherit" /> : children}
  </Button>
)
