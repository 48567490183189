import { Auth } from '@aws-amplify/auth'
import { Amplify, API } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom'

import config from './config'
import RootRouter from './content/RootRouter'

Amplify.configure(config)
Auth.configure(config)
API.configure(config)

ReactDOM.render(
    <React.StrictMode>
        <RootRouter />
    </React.StrictMode>,
    document.getElementById('root'),

)
