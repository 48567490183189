import { createTheme, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

export const buttonTheme = createTheme({
    palette: {
        primary: {
            main: green[500],
            contrastText: '#fff'
        }
    },
    typography: {
        button: {
            textTransform: 'none',
        }
    }
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useTableStyles = makeStyles((theme) => ({
    table: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '60%',
        margin: 'auto',
        marginBottom: 20,
        position: 'relative',
        zIndex: 5
    },
    tableWithoutBottomButtons: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '85%',
        margin: 'auto',
        marginBottom: '10%',
        position: 'relative',
        zIndex: 5
    },
    divLogo: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    imageLogo: {
        maxWidth: 150,
        maxHeight: 150
    },
    headline: {
        textAlign: 'center',
        fontFamily: 'Encode, sans-serif',
        fontSize: 16,
    },
    divTable: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 40,
        paddingLeft: 40
    },
    divInputs: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 30,
        paddingLeft: 30,
        marginTop: 30
    },
    divRow: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row'
    }
}))
