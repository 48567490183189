import {
    Button, Grid, Typography
} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { Loading } from '../../content/Loading'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { homeworkStyles } from '../../styles/HomeworkStyle'
import Routes from '../../utils/routes'
import { getMyDetails } from '../../utils/Services/student-services'
import {
    AnswerType,
    AssignedQuestionType, AssignedQuizType
} from '../../utils/types'
import { fireWrap, wrap } from '../../utils/wrapers'
import { QuizHeader } from '../atoms/QuizHeader'
import { QuestionValidator } from '../molecules/QuestionValidator'
import { QuizQuestions } from '../molecules/QuizQuestions'
import { SubmitQuizContainer } from '../molecules/SubmitQuizContainer'

interface Props {
    quiz: AssignedQuizType
    submitAnswerFunc(quizId: string, questionIndex: number, answer: string, sharedUrl?: string): Promise<AnswerType>
    onFinish?(questions: AssignedQuestionType[]): void,
    isPublic: boolean | null
}

export const QuizValidator: React.FC<Props> = ({
    quiz, onFinish, submitAnswerFunc, isPublic
}) => {
    const [isAnswered, setIsAnswered] = useState(false)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>()
    const [questions, setQuestions] = useState(quiz.questions)
    const [submitting, setSubmitting] = useState(false)
    const [finishLastQuestion, setFinishLastQuestion] = useState<boolean>(false)
    const [finished, setFinished] = useState(false)
    const [videoLoading, setVideoLoading] = React.useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hasConfetti, setHasConfetti] = useState<boolean>()
    const [hasAudio, setHasAudio] = useState<boolean>()
    const [busy, setBusy] = React.useState(false)
    const classes = buttonStyles()
    const classesHomework = homeworkStyles()

    useEffect(() => {
        setCurrentQuestionIndex(0)
        setQuestions(quiz.questions)
    }, [quiz])

    useEffect(() => {
        if (isPublic === null) {
            return
        }
        if (isPublic) {
            setHasConfetti(true)
            setHasAudio(true)

        } else if (!localStorage.getItem('confetti') || !localStorage.getItem('hasAudio')) {
            fireWrap(async () => {
                const { confetti, hasAudio: audio } = await getMyDetails()
                setHasConfetti(confetti)
                setHasAudio(audio)
                localStorage.setItem('confetti', JSON.stringify(confetti))
                localStorage.setItem('hasAudio', JSON.stringify(audio))
            }, {
                operation: 'Loading Data',
                stateFunc: setBusy
            })
        } else {
            setHasConfetti(JSON.parse(localStorage.getItem('confetti') as string))
            setHasAudio(JSON.parse(
                localStorage.getItem('hasAudio') !== 'undefined' 
                    ? localStorage.getItem('hasAudio') as string : 'true'
            ))
        }

    }, [isPublic])

    if (!quiz.questions || !quiz.questions.length || currentQuestionIndex === undefined) {
        return <Typography style={{ textAlign: 'center' }}>Hov, ingen spørgsmål!</Typography>
    }

    const question = questions[currentQuestionIndex]

    const selectPrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            navigateToQuestion(questions[currentQuestionIndex - 1], currentQuestionIndex - 1)
        }
    }

    const selectNextQuestion = () => {
        if (currentQuestionIndex < quiz.questions!!.length - 1) {
            navigateToQuestion(questions[currentQuestionIndex + 1], currentQuestionIndex + 1)
        }
    }

    const submitAnswer = async (answer: string, sharedUrl?: string) => {
        const r = await wrap(
            async () => submitAnswerFunc(quiz.id, currentQuestionIndex, answer, sharedUrl),
            {
                operation: 'Submitting Answer',
                stateFunc: setSubmitting
            }
        )

        const newQuestions = [...questions]
        newQuestions[currentQuestionIndex].givenAnswer = r

        setQuestions(newQuestions)

        return r.correct
    }

    const finishQuiz = async () => {
        if (onFinish) onFinish(questions)

        setFinished(true)
    }
    const navigateToQuestion = (item: AssignedQuestionType, index: number) => {
        if (item.vimeoVideoId && item.vimeoVideoId !== quiz.questions[currentQuestionIndex].vimeoVideoId) {
            setVideoLoading(true)
        }
        if (item.youtubeVideoId && item.youtubeVideoId !== quiz.questions[currentQuestionIndex].youtubeVideoId) {
            setVideoLoading(true)
        }
        setCurrentQuestionIndex(index)
    }

    if (finished) {
        return <Redirect to={Routes.Lessons.path} />
    }

    if (busy) {
        return (
            <Loading />
        )
    }

    return (
        <>
            {finishLastQuestion ? (
                <SubmitQuizContainer finishQuiz={finishQuiz} />
            )
                : (
                    <Grid container>
                        <Grid item sm={12} md={10} suppressContentEditableWarning>
                            <QuizHeader quiz={quiz} />
                            <QuestionValidator
                                isAnswered={isAnswered}
                                setIsAnswered={setIsAnswered}
                                hasAudio={hasAudio as boolean}
                                confetti={hasConfetti as boolean}
                                question={question}
                                disabled={submitting}
                                answer={questions[currentQuestionIndex].givenAnswer}
                                onSubmit={submitAnswer}
                                canGoPrevious={currentQuestionIndex > 0}
                                onGoPrevious={selectPrevQuestion}
                                canGoNext={currentQuestionIndex < questions.length - 1}
                                isQuizOpen={!quiz.onlyOrderedResponse}
                                onGoNext={selectNextQuestion}
                                videoLoading={videoLoading}
                                setVideoLoading={setVideoLoading} />
                            {currentQuestionIndex === quiz.questions.length - 1
                                && questions[currentQuestionIndex].givenAnswer
                                && (
                                    <Container className={classesHomework.wrap}>
                                        <Button
                                            disabled={submitting}
                                            className={classes.saveButton}
                                            onClick={() => setFinishLastQuestion(true)}>
                                            Klar til aflevering!
                                        </Button>
                                    </Container>
                                )}
                        </Grid>
                        <Grid item sm={12} md={2} style={{ margin: '0 auto' }}>
                            <QuizQuestions
                                setIsAnswered={setIsAnswered}
                                quiz={quiz}
                                question={question}
                                videoLoading={videoLoading}
                                navigateToQuestion={navigateToQuestion} />
                        </Grid>

                    </Grid>
                )}
        </>
    )
}
