import {
    Box, Button, createStyles, makeStyles, Modal, ThemeProvider
} from '@material-ui/core'
import * as React from 'react'
import { useAsync } from 'react-async-hook'

import { videoLibraryContainer } from '../../styles/VideoStyles'
import { useLoadingManager } from '../../utils/hooks'
import { showMessage } from '../../utils/notifications'
import {
    addQuestionToCommunityQuiz,
    listStudentQuizzes,
    updateCommunityQuizQuestions
} from '../../utils/Services/community-quiz-service'
import { uploadFile } from '../../utils/Services/file-service'
import { buttonTheme } from '../../utils/styles'
import { FileType, Question } from '../../utils/types'
import { FileUpload } from '../molecules/FileUpload'
import { MultipleAnswers } from '../molecules/MultipleAnswers'
import { RichTextEditor } from '../molecules/RichTextEditor'
import { TextInputTemplate } from '../molecules/TextInputTemplate'
import { VideoInput } from '../molecules/VideoInput'
import { drawerWidth } from '../wrappers/BodyWithTopBar'
import ConfirmDialog from './ConfirmDialog'
import GoBackHeader from './GoBackHeader'

interface Props {
    setLoading?: (value: boolean) => void
    quizId: string,
    indexOfQuiz?: number,
    openModal: boolean,
    setOpenModal: () => void,
    type: 'create' | 'edit',
    title: string,
    explanation: string,
    vimeoVideoId: string,
    youtubeVideoId: string,
    pictureFile: FileType | undefined,
    responses: string[],
    questions: Question[],
    setQuestions: (questions: Question[]) => void,
}

const useStyles = makeStyles((theme) => createStyles({
    modal: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        },
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        backgroundColor: 'white',
        border: '2px solid #000',
        marginLeft: drawerWidth / 2,
        padding: '20px 150px 20px 150px',
        maxHeight: 'calc(100% - 20px)',
        overflowY: 'auto',
        zIndex: 32
    },
}))

export const QuestionModal: React.FC<Props> = ({
    openModal, setOpenModal, type, title, explanation, questions, setQuestions,
    vimeoVideoId, youtubeVideoId, pictureFile, responses, quizId, indexOfQuiz
}) => {

    const [displayHistory, setDisplayHistory] = React.useState<boolean>(false)

    const toggleDisplayHistory = (event: any) => {
        setDisplayHistory(event.target.checked)
    }

    const options = {
        displayHistory,
        operators: [
            ['\\sqrt{x}', '\\sqrt'],
            ['\\sqrt[3]{x}', '\\sqrt[3]{}'],
            ['\\sqrt[n]{x}', '\\nthroot'],
            ['\\frac{x}{y}', '\\frac'],
        ]
    }

    const fetchStudentQuizzes = useAsync(listStudentQuizzes, [])
    const { startLoading, stopLoading } = useLoadingManager()
    const classes = useStyles()
    const [questionTitle, setQuestionTitle] = React.useState<string>(title)
    const [questionExplanation, setQuestionExplanation] = React.useState<string>(explanation)
    const [questionVimeoId, setQuestionVimeoId] = React.useState<string>(vimeoVideoId)
    const [questionYoutubeId, setQuestionYoutubeId] = React.useState<string>(youtubeVideoId)
    const [questionPictureFile, setQuestionPictureFile] = React.useState<FileType | undefined>(pictureFile)
    const [questionResponses, setQuestionResponses] = React.useState<string[]>(responses)

    const [confirmDelete, setConfirmDelete] = React.useState(false)

    const classes2 = videoLibraryContainer()
    React.useEffect(() => {
        startLoading()
        setQuestionTitle(title)
        setQuestionExplanation(explanation)
        setQuestionYoutubeId(youtubeVideoId || '')
        setQuestionVimeoId(vimeoVideoId || '')
        setQuestionPictureFile(pictureFile)
        setQuestionResponses(responses)
        stopLoading()
    }, [indexOfQuiz])

    const checkNumberOfAnswers = () => {
        if (questionResponses.length > 0) return true
        else return false
    }
    const handleDeleteQuestion = async (event: any, index: number | undefined) => {
        if (index || index === 0) {
            questions.splice(index, 1)
            startLoading()
            setOpenModal()
            const res = await updateCommunityQuizQuestions(quizId, questions)
            setQuestions(res.questions as [])
            stopLoading()
            fetchStudentQuizzes.execute()
        }
    }
    const addQuestion = async () => {
        if (!checkNumberOfAnswers()) {
            showMessage('Du skal skrive mindst én svarmulighed', 'error')
            return
        }
        startLoading()
        try {
            await addQuestionToCommunityQuiz(quizId, questionTitle, questionExplanation,
                questionResponses, questionVimeoId, questionYoutubeId, questionPictureFile)
            fetchStudentQuizzes.execute()
            setQuestions([...questions, {
                title: questionTitle,
                questionIndex: questions.length + 1,
                explanation: questionExplanation,
                responses: questionResponses,
                vimeoVideoId: questionVimeoId || '',
                youtubeVideoId: questionYoutubeId || '',
                picture: questionPictureFile
            }])
        } catch (err) { showMessage(err as string) }
        stopLoading()
        setOpenModal()
    }

    const updateQuestion = async () => {
        startLoading()
        try {
            if (questions && indexOfQuiz !== undefined) {
                const newQuestions = [...questions]
                const questionToBeUpdated = newQuestions[indexOfQuiz]
                questionToBeUpdated.title = questionTitle
                questionToBeUpdated.explanation = questionExplanation
                questionToBeUpdated.vimeoVideoId = questionVimeoId || ''
                questionToBeUpdated.youtubeVideoId = questionYoutubeId || ''
                questionToBeUpdated.picture = questionPictureFile
                questionToBeUpdated.responses = questionResponses
                newQuestions.splice(indexOfQuiz, 1, questionToBeUpdated)

                setQuestions(newQuestions)
            }
        } catch (err) { showMessage(err as string) }
        setOpenModal()
        stopLoading()
    }

    return (
        <Modal
            open={openModal}
            onClose={() => setOpenModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={classes.modal}>
                <GoBackHeader
                    headerText={type === 'create' ? 'Ny opgave' : 'Redigere opgave'}
                    onClick={() => setOpenModal()} />
                <TextInputTemplate
                    value={questionTitle}
                    onChange={setQuestionTitle}
                    label="Giv opgave en navn" />
                {/* <Link href="https://katex.org/docs/supported.html" target="_blank">
                    <small>Templates for math symbols</small>
                </Link> */}
                <RichTextEditor
                    options={options}
                    key={JSON.stringify(options)}
                    textInput={questionExplanation}
                    setTextInput={setQuestionExplanation} />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>
                    Vis formelhistorik:
                    <input
                        type="checkbox"
                        className="option"
                        onChange={toggleDisplayHistory} />
                </label>
                <VideoInput
                    vimeoVideoId={questionVimeoId}
                    onChangeVimeo={setQuestionVimeoId}
                    youtubeVideoId={questionYoutubeId}
                    onChange={setQuestionYoutubeId} />
                <p className={classes2.smallText}>
                    Max 5 mb
                </p>
                <FileUpload
                    file={questionPictureFile}
                    buttonText="Vælg fil"
                    allowedType="image"
                    underText="Du kan tilføje et billede i formatet .png, .jpeg, .jpg eller .gif"
                    uploadFunc={uploadFile}
                    onChange={setQuestionPictureFile} />

                <MultipleAnswers
                    underText="Indtast svar på spørgsmålet og tryk enter."
                    label="Svar"
                    answers={questionResponses}
                    onChange={setQuestionResponses} />
                <ThemeProvider theme={buttonTheme}>
                    <div style={{ display: 'flex', flex: 1, marginTop: 10 }}>
                        <Button
                            disabled={!questionTitle.length}
                            variant="contained"
                            color="primary"
                            onClick={type === 'create' ? addQuestion : updateQuestion}>
                            {type === 'create' ? 'Opret spørgsmål' : 'Opdater spørgsmål'}
                        </Button>
                        {type === 'edit' && (
                            <Button
                                style={{ marginLeft: 25 }}
                                disabled={!questionTitle.length}
                                variant="contained"
                                color="secondary"
                                onClick={() => setConfirmDelete(true)}>
                                Slet spørgsmål
                            </Button>
                        )}
                    </div>
                </ThemeProvider>
                <ConfirmDialog
                    text="Er du sikker på at du vil slette denne spørgsmål?"
                    open={confirmDelete}
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={(e) => handleDeleteQuestion(e, indexOfQuiz)} />
            </Box>
        </Modal>
    )
}
