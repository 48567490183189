import { showMessage } from './notifications'

interface WrapOpts {
    override?: boolean,
    stateFunc?(busy: boolean): void
    errorStateFunc?(busy: boolean): void
    operation?: string
    showSuccess?: boolean
}

export async function wrap<T>(f: () => Promise<T>, opts?: WrapOpts) {
    try {
        if (opts?.stateFunc) {
            opts.stateFunc(true)
        }

        const r = await f()

        if (opts?.showSuccess) {
            showMessage(`Successfully completed ${opts.operation || 'Contacting the Server'}.`, 'success')
        }

        if (opts?.errorStateFunc) {
            opts.errorStateFunc(false)
        }

        return r
    } catch (error) {
        showMessage(opts?.override ? String(opts?.operation)
            : `There was an error while ${opts?.operation
            || 'Contacting the Server'}!`, 'error')

        if (opts?.errorStateFunc) {
            opts.errorStateFunc(true)
        }
        throw error
    } finally {
        if (opts?.stateFunc) {
            opts.stateFunc(false)
        }
    }
}

export function fireWrap<T>(f: () => Promise<T>, opts?: WrapOpts): void {
    wrap(f, opts).then()
}
