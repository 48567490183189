import { Link, Typography } from '@material-ui/core'
import React from 'react'

type Props = {
    title: string;
    disabled: boolean;
    correct: boolean;
    currentQuestionTitle: string;
    onQuestionItemClick: () => void;
}

export const QuizQuestionLink: React.FC<Props> = ({
    disabled, title, currentQuestionTitle, onQuestionItemClick, correct
}) => {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
            onClick={onQuestionItemClick}
            style={{
                cursor: disabled ? 'auto' : 'pointer',
                color: correct ? '#6fd995' : 'primary'
            }}
            component="button"
            underline={disabled ? 'none' : 'hover'}
            color={disabled ? 'textSecondary' : 'primary'}
            disabled={disabled}>
            <Typography 
                variant="body1" 
                style={{ 
                    textAlign: 'center', 
                    marginBlockStart: currentQuestionTitle === title ? '1em' : 0, 
                    marginBlockEnd: currentQuestionTitle === title ? '1em' : 0, 
                }}>
                {title}
            </Typography>
        </Link>
    )
}
