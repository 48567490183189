import * as React from 'react'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { useUniLoginReply } from '../../utils'
import Paths from '../../utils/routes'
import { restoreCognitoSession, useCognito } from '../../utils/Services/cognito-services'
import { finalizeLogInStudentWithUniLogin } from '../../utils/Services/student-services'
import { fireWrap } from '../../utils/wrapers'
import { Loading } from '../Loading'

export const CompleteLogInWithUniLogin: React.FC = () => {
    const { signedIn } = useCognito()
    const [error, setError] = useState(false)
    const uniLoginReply = useUniLoginReply()

    useEffect(() => {
        if (uniLoginReply) {
            fireWrap(async () => {
                const creds = await finalizeLogInStudentWithUniLogin({
                    uniLoginUsername: uniLoginReply.user,
                    uniLoginAuth: uniLoginReply.auth,
                    uniLoginTimestamp: uniLoginReply.timestamp,
                    group: 'student',
                })

                restoreCognitoSession(creds)
                window.location.search = ''

            }, {
                override: true,
                operation: `Det ser desværre ud til at du ikke har en 
                Geeksterkonto- sig det til din lærer så du kan blive oprettet`,
                errorStateFunc: setError
            })
        }
    }, [])

    if (error) {
        return (
            <Redirect to="/" />
        )
    }

    if (signedIn) {
        return (
            <Redirect to={Paths.Lessons.path} />
        )
    }

    return (
        <Loading />
    )
}
