/* eslint-disable import/extensions */
/* eslint-disable import/first */
/* eslint-disable  simple-import-sort/sort */
import katex from 'katex'

window.katex = katex
import 'katex/dist/katex.css'
import './styles.css'

// Wuill dependency

import 'react-quill/dist/quill.snow.css'

// MathQuill dependency
import './jquery'

import 'mathquill/build/mathquill.js'
import 'mathquill/build/mathquill.css'
import mathquill4quill from 'mathquill4quill'
import 'mathquill4quill/mathquill4quill.css'

import ReactQuill, { Quill } from 'react-quill'
import '../../../styles/katex.css'

// eslint-disable-next-line import/extensions
// Import React
import React, { createRef, useEffect } from 'react'

window.katex = katex

interface Props {
    textInput: string,
    setTextInput: (text: string) => void,
    options: {
        displayHistory: boolean;
        operators: string[][];
    }
}

export const RichTextEditor: React.FC<Props> = ({ textInput, setTextInput, options }) => {

    const onTextChange = (input: string) => {
        setTextInput(input)
    }

    const reactQuill = createRef()
    useEffect(() => {
        const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex })
        enableMathQuillFormulaAuthoring(
            // @ts-ignore.
            reactQuill.current.editor,
            options
        )
    }, [])

    return (
        <>
            <ReactQuill
                // @ts-ignore.
                ref={reactQuill}
                id="editor"
                theme="snow"
                modules={{
                    formula: true,
                    toolbar: [
                        [{ header: [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                        ['link'],
                        ['formula', 'clean'],
                    ],
                }}
                placeholder="Beskriv din opgave med tekst..."
                value={textInput}
                onChange={onTextChange} />
        </>
    )
}
