import {
    createStyles, makeStyles, Paper, TableContainer
} from '@material-ui/core'
import * as React from 'react'

import { Loading } from '../../content/Loading'
import { useLoadingManager } from '../../utils/hooks'
import { createInitiallyCommunityQuiz } from '../../utils/Services/community-quiz-service'
// import { useLoadingManager } from '../../utils/hooks'
// import {
//     createInitiallyCommunityQuiz,
// } from '../../utils/Services/community-quiz-service'
import Alert from '../atoms/Alert'
import FormButtons from '../atoms/FormButtons'
import GoBackHeader from '../atoms/GoBackHeader'
import { FirstCreateCommunityQuizStep } from '../molecules/FirstCreateCommunityQuizStep'
// import CreateScreenTemplate from '../molecules/CreateScreenTemplate'
// import { FirstCreateCommunityQuizStep } from '../molecules/FirstCreateCommunityQuizStep'

interface Props {
    setCreateNew: (boolean: boolean) => void,
    setFinishCreatingId: (id: string) => void,
    onQuizCreate: () => void
}

const useTableStyles = makeStyles(() => createStyles({
    table: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '85%',
        margin: 'auto',
        marginBottom: 20,
        position: 'relative',
        zIndex: 5,
        // minHeight: 400
    },

    table1: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '85%',
        margin: 'auto',
        marginBottom: 20,
        position: 'relative',
        zIndex: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // minHeight: 400
    },
    tableWithoutBottomButtons: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '85%',
        margin: 'auto',
        marginBottom: '10%',
        position: 'relative',
        zIndex: 5,
        // minHeight: 400
    },
    divButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 40,
        paddingLeft: 40,
    },
    divInputs: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 30,
        paddingLeft: 30,
        marginTop: 50,
        maxWidth: '85%',
        margin: 'auto'
    },
    divInputRow: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    checkBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    divAuthButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 30
    },
    authLogoContainer: {
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 30
    },
    authLogoContainer2: {
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
    },
    authBackground: {
        minHeight: '100vh',
        margin: -8,
        marginTop: -30,
        marginBottom: -10,
        paddingBottom: 10,
        display: 'fixed',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    authBackground2: {
        minHeight: '100vh',
        margin: -8,
        marginTop: -30,
        marginBottom: -10,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }
}))

const FirstCreateCommunityQuizScreen: React.FC<Props> = ({ setCreateNew, setFinishCreatingId, onQuizCreate }) => {
    const tableClasses = useTableStyles()
    const { loading, startLoading, stopLoading } = useLoadingManager()

    const [title, setTitle] = React.useState<string>('')
    const [category, setCategory] = React.useState<string[]>([])
    const [createError, setCreateError] = React.useState<boolean>(false)
    const [topics, setTopics] = React.useState<string[]>([])
    const [selectedGrades, setSelectedGrades] = React.useState<string[]>([])
    const [openQuiz, setOpenQuiz] = React.useState<boolean>(false)
    const [publicQuiz, setPublicQuiz] = React.useState<boolean>(false)

    const [errorMessage, setErrorMessage] = React.useState<string>('')

    const handleGoBack = () => {
        setCreateNew(false)
    }
    const onCreate = async () => {
        if (!validateForm()) { setCreateError(true); setErrorMessage('Please fill out all the inputs'); return }
        const filteredGrades = selectedGrades.flatMap((grade) => {
            if (!Number.isNaN(parseInt(grade.slice(0, -8), 10))) return parseInt(grade.slice(0, -8), 10)
            return []
        })
        startLoading()
        const createdQuiz = await createInitiallyCommunityQuiz(title, topics, category,
            filteredGrades, publicQuiz, !openQuiz)
        onQuizCreate()
        stopLoading()
        setTitle('')
        setCreateNew(false)
        setFinishCreatingId(createdQuiz.id)
    }
    const validateForm = () => {
        if (title.length > 0) return true
        return false
    }
    if (loading) {
        return <Loading />
    }
    return (
        <form noValidate>
            <TableContainer className={tableClasses.table} component={Paper}>
                <GoBackHeader headerText="Ny quiz" buttonText="Mine quizzer" onClick={handleGoBack} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {!loading && (
                        <FirstCreateCommunityQuizStep
                            title={title}
                            setTitle={setTitle}
                            topics={topics}
                            categories={category}
                            setTopics={setTopics}
                            setCategory={setCategory}
                            openQuiz={openQuiz}
                            setOpenQuiz={setOpenQuiz}
                            publicQuiz={publicQuiz}
                            setPublicQuiz={setPublicQuiz}
                            selectedGrades={selectedGrades}
                            setSelectedGrades={setSelectedGrades} />
                    )}
                    <Alert
                        text={errorMessage}
                        setOpen={setCreateError}
                        open={createError}
                        type="alert" />
                </div>
            </TableContainer>
            <FormButtons
                createButtonText="Næste"
                onClickCreate={onCreate}
                disableCreate={!validateForm()}
                disableButtons={loading} />
        </form>
    )
}
export default FirstCreateCommunityQuizScreen
