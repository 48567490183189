/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography
} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import React from 'react'
import { useAsync } from 'react-async-hook'
import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom'

import FirstCreateCommunityQuizScreen from '../../components/organisms/FirstCreateCommunityQuizScreen'
import SecondCreateCommunityQuizScreen from '../../components/organisms/SecondCreateCommunityQuizScreen'
import UpdateCommunityQuizScreen from '../../components/organisms/UpdateCommunityQuizScreen'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { buttonTheme } from '../../styles/TableStyles'
import { showMessage } from '../../utils/notifications'
import { listStudentQuizzes } from '../../utils/Services/community-quiz-service'
import { StudentQuizType } from '../../utils/types'
import { Loading } from '../Loading'

const useTableStyles = makeStyles(() => createStyles({
  table: {
    padding: 20,
    borderRadius: 25,
    maxWidth: '85%',
    margin: 'auto',
    marginBottom: 20,
    position: 'relative',
    zIndex: 5,
  },

  table1: {
    padding: 20,
    borderRadius: 25,
    maxWidth: '85%',
    margin: 'auto',
    marginBottom: 20,
    position: 'relative',
    zIndex: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // minHeight: 400
  },
  tableWithoutBottomButtons: {
    padding: 20,
    borderRadius: 25,
    maxWidth: '85%',
    margin: 'auto',
    marginBottom: '10%',
    position: 'relative',
    zIndex: 5,
    // minHeight: 400
  },
  divButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 40,
    paddingLeft: 40,
  },
  divInputs: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 30,
    paddingLeft: 30,
    marginTop: 50,
    maxWidth: '85%',
    margin: 'auto'
  },
  divInputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  checkBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  divAuthButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 30
  },
  authLogoContainer: {
    display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 30
  },
  authLogoContainer2: {
    display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
  },
  authBackground: {
    minHeight: '100vh',
    margin: -8,
    marginTop: -30,
    marginBottom: -10,
    paddingBottom: 10,
    display: 'fixed',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  authBackground2: {
    minHeight: '100vh',
    margin: -8,
    marginTop: -30,
    marginBottom: -10,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }
}))

const buttonStyles = makeStyles((theme) => ({
  leftButton: {
    marginLeft: '7.5%',
    position: 'relative',
    zIndex: 5
  },
  otherButtons: {
    marginLeft: '2%',
    position: 'relative',
    zIndex: 5
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  saveButton: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderWidth: 1,
    backgroundColor: '#F1ECE8',
    borderColor: '#28a745',
    borderRadius: 5,
    borderStyle: 'solid',
    color: '#28a745',
    textAlign: 'center',
    '&:hover, &:focus': {
      backgroundColor: '#28a745',
      color: '#FFFFFF',
    },
    marginBottom: 10
  },
  alertButton: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderWidth: 1,
    backgroundColor: '#F1ECE8',
    borderColor: '#ffc107',
    borderRadius: 5,
    borderStyle: 'solid',
    color: '#ffc107',
    textAlign: 'center',
    '&:hover, &:focus': {
      backgroundColor: '#ffc107',
      color: '#FFFFFF',
    }
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderWidth: 1,
    backgroundColor: '#F1ECE8',
    borderColor: '#dc3545',
    borderRadius: 5,
    borderStyle: 'solid',
    color: '#dc3545',
    textAlign: 'center',
    '&:hover, &:focus': {
      backgroundColor: '#dc3545',
      color: '#FFFFFF',
    },
    marginBottom: 10
  },
  uploadButton: {
    width: '100%',
    marginRight: theme.spacing(1),
    borderWidth: 1,
    backgroundColor: '#F1ECE8',
    borderColor: '#063354',
    borderRadius: 5,
    borderStyle: 'solid',
    color: '#063354',
    textAlign: 'center',
    '&:hover, &:focus': {
      backgroundColor: '#063354',
      color: '#FFFFFF',
    }
  },
  download: {
    backgroundColor: '#03a9f4',
    color: 'white',
    margin: 10,
    '&:hover, &:focus': {
      backgroundColor: '#063354',
      color: '#FFFFFF',
    }
  },
  close: {
    backgroundColor: 'red',
    color: 'white',
    margin: 10,
    '&:hover, &:focus': {
      backgroundColor: 'red',
      color: '#FFFFFF',
    }
  },
  forgotPassButton: {
    margin: 0,
    borderWidth: 1,
    backgroundColor: 'white',
    borderColor: '#ffc107',
    borderRadius: 5,
    borderStyle: 'solid',
    color: '#ffc107',
    textAlign: 'center',
    '&:hover, &:focus': {
      backgroundColor: '#ffc107',
      color: '#FFFFFF',
    }
  },
  copyClipboard: {
    marginBottom: 15,
    minWidth: 'fit-content',
    cursor: 'pointer',
    marginTop: 15,
  },
  authButton: {
    position: 'relative',
    zIndex: 5
  },
  largeAuthButton: {
    width: '50%',
    minWidth: 200,
    maxWidth: 350,
    height: 55,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#3C8CAC',
    cursor: 'pointer',
    fontSize: 20,
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10
  },
  largeUniButton: {
    width: '50%',
    minWidth: 200,
    maxWidth: 350,
    height: 55,
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: '#FFFFFF',
    borderStyle: 'solid',
    borderColor: '#3C8CAC',
    cursor: 'pointer',
    fontSize: 20,
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    '&:hover, &:focus, &active': {
      backgroundColor: '#3C8CAC',
      color: '#FFFFFF',
    }
  },
}))

export const StudentQuizzes: React.FC = () => {
  const location: any = useLocation()

  const myparam = location.state ? location.state.quiz : undefined
  const [selected, setSelected] = React.useState<string>(myparam)
  const [description, setDescription] = React.useState<string>('')
  const [createNew, setCreateNew] = React.useState<boolean>(false)
  const [finishCreatingId, setFinishCreatingId] = React.useState<string>('')
  const buttonClasses = buttonStyles()
  const tableClasses = useTableStyles()
  const fetchStudentQuizzes = useAsync(listStudentQuizzes, [])
  const { result, error, loading }:
    {
      result: StudentQuizType[] | undefined,
      error: Error | undefined,
      loading: boolean | undefined
    } = fetchStudentQuizzes

  const onQuizUpdate = () => {
    // to update default quiz screen
    fetchStudentQuizzes.execute()
  }
  const isSelected = (itemSelected: string) => {
    setSelected(itemSelected)
  }

  const renderCreateNewScreen = () => (
    <FirstCreateCommunityQuizScreen
      onQuizCreate={onQuizUpdate}
      setCreateNew={(boolean: boolean) => setCreateNew(boolean)}
      setFinishCreatingId={setFinishCreatingId} />
  )

  const renderFinishCreatingScreen = () => (
    <SecondCreateCommunityQuizScreen
      description={description}
      setDescription={setDescription}
      onQuizCreate={onQuizUpdate}
      justUpdateQuestions={false}
      setFinishCreatingId={setFinishCreatingId}
      createdQuizId={finishCreatingId} />
  )

  const handleTableRowClick = (quizId: string, quizStatus: string) => {
    if (quizStatus === 'unpublished') setFinishCreatingId(quizId)
    else setSelected(quizId)
  }

  const renderDefaultScreen = () => (
    <>
      <TableContainer className={tableClasses.table} component={Paper}>
        <Typography variant="h6" style={{ textAlign: 'center' }}>Mine quizzer</Typography>
        {result && result.length === 0
          ? !loading
          && (<Typography align="center">Ingen quizzer fundet.</Typography>)
          : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Navn</TableCell>
                  <TableCell>Beskrivelse</TableCell>
                  <TableCell>Kategori</TableCell>
                  <TableCell>Emner</TableCell>
                  <TableCell>Spørgsmål</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Udgives</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result && result.map((item) => (
                  <TableRow
                    style={{ cursor: 'pointer' }}
                    key={item.id}
                    hover
                    onClick={() => handleTableRowClick(item.id, item.status as string)}>
                    <TableCell component="th" scope="row">
                      <b>{item.title}</b>
                    </TableCell>
                    <TableCell component="th" scope="row"><b>{item.description}</b></TableCell>
                    <TableCell component="th" scope="row">
                      <b>{item.categories?.join(', ')}</b>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <b>{item.topics.join(', ')}</b>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <b>{item.questions?.length || 0}</b>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row">
                      <div style={{ alignItems: 'center', display: 'flex', flex: 1 }}>
                        {item.status !== 'approved'
                          && <ErrorOutlineIcon style={{ fill: 'red' }} />}
                        {item.status === 'rejected' && <b>afvist</b>}
                        {item.status === 'approved' && <b>godkendt</b>}
                        {item.status === 'unpublished' && <b>ikke udgivet</b>}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <b>{item.isPublic ? 'offentlig' : 'privat'}</b>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
      </TableContainer>
      <ThemeProvider theme={buttonTheme}>
        <Button
          className={buttonClasses.leftButton}
          style={{
            color: '#fff',
            backgroundColor: '#3f51b5',
            textTransform: 'uppercase'
          }}
          variant="contained"
          color="primary"
          onClick={() => setCreateNew(true)}>
          Opret ny quiz
        </Button>
      </ThemeProvider>
      <ReactPlayer
        style={{ marginTop: 60, }}
        url="https://player.vimeo.com/video/787681809"
        width="100%"
        controls />
    </>
  )

  const renderUpdateScreen = () => {
    if (selected) {
      return (
        <UpdateCommunityQuizScreen
          onQuizUpdate={onQuizUpdate}
          selected={selected}
          isSelected={isSelected}
          setFinishCreatingId={setFinishCreatingId} />
      )
    }
    return <Loading />

  }

  const renderUpdateQuestionsScreen = () => (
    <SecondCreateCommunityQuizScreen
      description={description}
      setDescription={setDescription}
      onQuizCreate={onQuizUpdate}
      justUpdateQuestions
      setFinishCreatingId={setFinishCreatingId}
      createdQuizId={finishCreatingId} />
  )

  if (loading) {
    return (
      // <BodyWithTopBar>
      <Loading />
      // </BodyWithTopBar>
    )
  }
  if (error) {
    showMessage(error.message)
  }

  return (
    <BodyWithTopBar>
      {loading && <Loading />}
      {!loading && !createNew && !finishCreatingId && !selected && renderDefaultScreen()}
      {!loading && createNew && !finishCreatingId && !selected && renderCreateNewScreen()}
      {!loading && !createNew && !finishCreatingId && selected && renderUpdateScreen()}
      {!loading && !createNew && finishCreatingId && !selected && renderFinishCreatingScreen()}
      {!loading && !createNew && finishCreatingId && selected && renderUpdateQuestionsScreen()}
    </BodyWithTopBar>
  )
}
