import moment from 'moment'

import { AssignedQuizType } from './types'

export const isQuizUpdated = (quiz: AssignedQuizType) => {
    return quiz.lastUpdated && moment.utc(quiz.lastUpdated).isAfter(moment.utc().subtract(10, 'days'))
}

export const getGradesByKeyword = (input: string[], grades: string[],
    schoolGradeKeyword: string, gradeLevel: string[], selectedGrades: string[]) => {
    let newGrades = grades
    if (input.includes(schoolGradeKeyword) || selectedGrades.includes(schoolGradeKeyword)) {
        if (input.includes(schoolGradeKeyword) && !selectedGrades.includes(schoolGradeKeyword)) {
            newGrades = Array.from(new Set([...selectedGrades, ...gradeLevel]))
        } else if (!input.includes(schoolGradeKeyword) && selectedGrades.includes(schoolGradeKeyword)) {
            newGrades = selectedGrades.filter((item) => !gradeLevel.includes(item))
        }

        if (!gradeLevel.every((r) => newGrades.includes(r))) {
            if (newGrades.indexOf(schoolGradeKeyword) > -1) {
                newGrades.splice(newGrades.indexOf(schoolGradeKeyword), 1)
            }
        }
    }
    return newGrades
}
