import {
    Button
} from '@material-ui/core'
import * as React from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'

interface Props {
    createButtonText: string,
    onClickCreate: () => void,
    disableButtons: boolean,
    disableCreate: boolean
}

const CreateButton: React.FC<Props> = ({
    createButtonText,
    onClickCreate,
    disableButtons,
    disableCreate
}) => {
    const buttonClasses = buttonStyles()
    return (
        <Button
            style={{
                backgroundColor:
                    (disableButtons || disableCreate) ? 'rgba(0, 0, 0, 0.26)'
                        : '#4caf50',
                textTransform: 'uppercase',
                color: (disableButtons || disableCreate) ? 'rgba(0, 0, 0, 0.12)' : '#fff'
            }}
            className={buttonClasses.leftButton}
            variant="contained"
            color="primary"
            onClick={onClickCreate}
            disabled={disableButtons || disableCreate}>
            {createButtonText}
        </Button>

    )
}
export default CreateButton
