import 'crypto-js/lib-typedarrays'

import { Button } from '@material-ui/core'
import { VerifiedUser } from '@material-ui/icons'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth, Hub } from 'aws-amplify'
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import logo from '../../assets/LogoGeekster.png'
import { BackgroundWithImage } from '../../components/atoms/BackgroundWithImage'
// import { UniloginButton } from '../../components/atoms/UniloginButton'
// import { AuthButton } from '../../components/molecules/AuthButtons'
import { getModalStyle } from '../../components/molecules/EditModalTemplate'
import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
// import { useTableStyles } from '../../styles/TableStyles'
import { showMessage } from '../../utils/notifications'
// import Paths from '../../utils/routes'
import { handleSignInError, useCognito } from '../../utils/Services/cognito-services'
// import { initiateLogInStudentWithUniLogin } from '../../utils/Services/student-services'
import { Group } from '../../utils/types'
// import { wrap } from '../../utils/wrapers'
import { Loading } from '../Loading'

const LoginAuthView: React.FC<{ onAuth(completed: boolean, user: any): void }> = ({ onAuth }) => {
    const btnClasses = buttonStyles()

    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [doing, setDoing] = React.useState(false)
    // const [redirectToUniLogin, setRedirectToUniLogin] = React.useState(false)
    // const [loginView, setLoginView] = React.useState<'not-selected' | 'normal'>('not-selected')

    // const tableClasses = useTableStyles()

    const canSubmit = username.length >= 2 && password.length > 5

    const logIn = async () => {
        if (!username || !password) {
            showMessage('Brugernavn eller adgangskode må ikke være tomt.', 'error')
            return
        }
        if (username.length < 4) {
            showMessage('Brugernavnet er ikke korrekt.', 'error')
            return
        }
        if (password.length < 5) {
            showMessage('Adgangskoden skal være på mindst 6 tegn.', 'error')
            return
        }

        try {
            const user = (await Auth.signIn(username, password))
            if (user) {
                const mustChangePassword = user.challengeName === 'NEW_PASSWORD_REQUIRED'
                if (!mustChangePassword && user instanceof CognitoUser) {
                    const p = user.getSignInUserSession()?.getAccessToken()?.decodePayload()
                    const gs: Group[] = (p && p['cognito:groups']) || []
                    const isStudent = gs.includes('student')

                    if (!isStudent) {
                        await Auth.signOut()
                        showMessage('User is not an student.', 'error')
                    }
                }

                onAuth(!mustChangePassword, user)
            }
        } catch (e) {
            const { message } = e as Error
            handleSignInError(message, showMessage)
        }
        onAuth(false, undefined)
        setDoing(false)
    }

    // const loginWithUniLogin = async () => {
    //     await wrap(async () => {
    //         const { clientUri } = await initiateLogInStudentWithUniLogin(
    //             `${window.location.origin}${Paths.CompleteLogInWithUniLogin.path}`
    //         )
    //         setRedirectToUniLogin(true)
    //         window.location.href = clientUri
    //     }, {
    //         operation: 'Logging In',
    //         stateFunc: setDoing
    //     })
    // }

    if (doing /* || redirectToUniLogin */) {
        return (
            <Loading />
        )
    }

    return (
        <div>
            {/* <div className={tableClasses.divAuthButtons}>
                <AuthButton
                    textColor={loginView === 'normal' ? '#FFFFFF' : '#000'}
                    backgroundColor={loginView === 'normal' ? '#3C8CAC' : '#FFFFFF'}
                    onClick={() => setLoginView('normal')}
                    title="Login med Geekster" />
                <UniloginButton onClick={loginWithUniLogin}/> 
            </div> 
            */}
            <>
                <TextInputTemplate
                    underText="Indtast det brugernavn, du brugte
                         til at oprette, eller det brugernavn, din lærer gav dig"
                    label="Brugernavn"
                    value={username}
                    onChange={setUsername}
                    type="email" />
                <TextInputTemplate
                    underText="Password"
                    label="Password"
                    value={password}
                    onChange={setPassword}
                    type="password" />
                <Button
                    onClick={logIn}
                    variant="contained"
                    size="small"
                    disabled={doing || !canSubmit}
                    className={btnClasses.saveButton}
                    startIcon={<VerifiedUser />}>Log ind
                </Button>
            </>

        </div>
    )
}

const LoginResetView: React.FC<{ user: any, onReset(success: boolean): void }> = ({ user, onReset }) => {
    const btnClasses = buttonStyles()

    const [newPassword, setNewPassword] = React.useState('')
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('')
    const [doing, setDoing] = React.useState(false)

    const handleSubmit = async () => {

        setDoing(true)
        try {
            await Auth.completeNewPassword(user, newPassword, {})

            onReset(true)
        } catch (e) {
            // @ts-ignore
            showMessage(`${e.message}`, 'error')
        }

        onReset(false)
        setDoing(false)
    }

    const canSubmit = newPassword.length > 5 && confirmNewPassword === newPassword

    return (
        <>
            <TextInputTemplate
                underText="Nyt kodeord"
                label="Nyt kodeord"
                value={newPassword}
                onChange={setNewPassword}
                type="password" />
            <TextInputTemplate
                underText="Bekræft ny adgangskode"
                label="Bekræft ny adgangskode"
                value={confirmNewPassword}
                onChange={setConfirmNewPassword}
                type="password" />
            <Button
                onClick={handleSubmit}
                disabled={!canSubmit || doing}
                variant="contained"
                size="small"
                className={btnClasses.saveButton}
                startIcon={<VerifiedUser />}>Log ind
            </Button>
        </>
    )
}

export const Login: React.FC = () => {
    const { signedIn } = useCognito()
    const classes = modalStyles()

    const [modalStyle] = React.useState(getModalStyle)
    const [stage, setStage] = React.useState<'auth' | 'reset' | 'next'>('auth')
    const [user, setUser] = React.useState<any>()

    if (stage === 'next' || signedIn) {
        return <Redirect to="/lektiers" />
    }

    const handleAuth = (completed: boolean, u: any) => {
        if (!u) {
            return
        }
        setUser(u)

        if (completed) {
            Hub.dispatch('auth', { event: 'signIn', data: u }) // todo: this should be removed!
            setStage('next')
        } else {
            setStage('reset')
        }
    }

    const handleReset = (success: boolean) => {
        if (success) {
            Hub.dispatch('auth', { event: 'signIn', data: user }) // todo: this should be removed!
            setStage('next')
        }
    }

    return (
        <BackgroundWithImage>
            <div
                style={{ background: 'linear-gradient(to right, #b2fefa, #0ed2f7)' }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.login}>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                        <img style={{ width: 100, height: 100 }} src={logo} alt="logo" />
                    </div>
                    <h2 style={{
                        textAlign: 'center',
                        fontWeight: 300,
                        fontFamily: 'Encode, sans-serif',
                        color: '#161313'
                    }}>
                        Geekster.dk | Elever Login
                    </h2>
                    <h4 style={{
                        textAlign: 'center',
                        fontWeight: 300,
                        fontFamily: 'Encode, sans-serif',
                        color: '#161313'
                    }}>
                        Log venligst ind med dine brugeroplysninger.
                    </h4>
                    {stage === 'auth' && <LoginAuthView onAuth={handleAuth} />}
                    {stage === 'reset' && <LoginResetView user={user} onReset={handleReset} />}
                </div>
            </div>
        </BackgroundWithImage>
    )
}
