import '../../styles/correct.css'

import SettingsIcon from '@material-ui/icons/Settings'
import * as React from 'react'

import { MainCard } from '../../components/molecules/MainCard'
import { ConfettiForm } from '../../components/organisms/ConfettiForm'
import { PasswordForm } from '../../components/organisms/PasswordForm'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'

interface Props {
    student: any;
}

export const Settings: React.FC<Props> = ({ student }: Props) => {

    return (

        <BodyWithTopBar>
            <MainCard icon={<SettingsIcon />} title="Indstillinger">
                <ConfettiForm student={student} />
                <PasswordForm />
            </MainCard>
        </BodyWithTopBar>
    )
}
