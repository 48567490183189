import {
    Button, Paper, TableContainer, ThemeProvider
} from '@material-ui/core'
import moment from 'moment'
// @ts-ignore
import qs from 'query-string'
import * as React from 'react'
import { useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import Logo from '../../assets/logoText.png'
// import unilogin from '../../assets/unilogin.png'
import { BackgroundWithImage } from '../../components/atoms/BackgroundWithImage'
// import { AuthButton } from '../../components/molecules/AuthButtons'
import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { Body } from '../../components/wrappers/Body'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { buttonTheme, useTableStyles } from '../../styles/SignUpStyles'
// import Paths from '../../utils/routes'
import {
    fulfillSignUpLink,
    // initiateSignUpStudentWithUniLogin,
    validateSignUpLink
} from '../../utils/Services/student-services'
import { SignUpIntentValidationType } from '../../utils/types'
import { fireWrap, wrap } from '../../utils/wrapers'
import { Loading } from '../Loading'

export const SignUp: React.FC = () => {
    const buttonClasses = buttonStyles()
    const tableClasses = useTableStyles()

    const [busy, setBusy] = React.useState(true)
    const [done, setDone] = React.useState(false)
    const [intent, setIntent] = React.useState<SignUpIntentValidationType>()
    const [username, setUsername] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [repeatPassword, setRepeatPassword] = React.useState<string>('')
    const [firstName, setFirstName] = React.useState<string>('')
    const [lastName, setLastName] = React.useState<string>('')
    // const [signUpView, setSignUpView] = React.useState(0)
    // const [signingUp, setSigningUp] = React.useState(false)
    // const [redirectToUniLogin, setRedirectToUniLogin] = React.useState(false)

    const { search } = useLocation()
    const { id } = qs.parse(search)

    // if (signingUp) {
    //     return <Loading />
    // }

    useEffect(() => {
        fireWrap(async () => {
            const k = await validateSignUpLink(id || '')
            setIntent(
                k
            )
        }, {
            operation: 'Verifying Invite',
            stateFunc: setBusy,
        })
    }, [])

    const signUp = async () => {
        let override = true
        let operation = 'Dette brugernavn er optaget. Vælg venligst et andet brugernavn'
        if (!firstName || !lastName || !username || !password || !repeatPassword || password !== repeatPassword) {
            override = false
            operation = 'Signing Up!'
        }
        await wrap(async () => {
            await fulfillSignUpLink(id, {
                firstName,
                lastName,
                username,
                password
            })

            setDone(true)
        }, {
            override,
            operation,
            stateFunc: setBusy,
            showSuccess: true
        })
    }

    // const signUpWithUniLogin = async () => {
    //     await wrap(async () => {
    //         const { clientUri } = await initiateSignUpStudentWithUniLogin(id, {
    //             username,
    //             firstName,
    //             lastName,
    //             password: 'will be ignored'
    //         },
    //             `${window.location.origin}${Paths.CompleteSignUpWithUniLogin.path}`)

    //         setRedirectToUniLogin(true)
    //         window.location.href = clientUri
    //     }, {
    //         stateFunc: setSigningUp,
    //         operation: 'Signing Up'
    //     }) 
    // }

    if (!id) {
        return <Redirect to="/" />
    }

    if (busy /* || redirectToUniLogin */) {
        return <Loading />
    }

    if (!intent) {
        return <Redirect to="/" />
    }

    if (done) {
        return <Redirect to="/login" />
    }

    return (
        <BackgroundWithImage>
            <div>
                <div className={tableClasses.divLogo}>
                    <img className={tableClasses.imageLogo} src={Logo} alt="logo" />
                </div>
                <Body>
                    <p className={tableClasses.headline}>
                        Kære elev, din lærer {intent.person.firstName} {intent.person.lastName} har
                        opfordret dig til at tilmelde dig
                    </p>
                    <p className={tableClasses.headline}>
                        Du har kun indtil {moment.utc(intent.expiresAt).local().format('DD/MM/YYYY')}
                        at gøre det.
                    </p>
                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <AuthButton
                            textColor={signUpView === 1 ? '#FFFFFF' : '#000'}
                            backgroundColor={signUpView === 1 ? '#3C8CAC' : '#FFFFFF'}
                            onClick={() => setSignUpView(1)}
                            title="Opret profil med Geekster" />
                        {/* <AuthButton
                            showLogo
                            source={unilogin}
                            alt="Unilogin logo"
                            textColor={signUpView === 2 ? '#FFFFFF' : '#000'}
                            backgroundColor={signUpView === 2 ? '#3C8CAC' : '#FFFFFF'}
                            onClick={() => setSignUpView(2)}
                            title="Opret profil med"/>
                    </div> */}
                    {/* {signUpView === 1 && ( */}
                    <form noValidate>
                        <TableContainer
                            style={{ backgroundColor: 'rgba(252,252,252,0.6)' }}
                            className={tableClasses.table}
                            component={Paper}>
                            <div className={tableClasses.divInputs}>
                                <TextInputTemplate
                                    value={username}
                                    onChange={setUsername}
                                    label="Brugernavn" />
                                <div className={tableClasses.divRow}>
                                    <TextInputTemplate
                                        value={password}
                                        label="Password"
                                        onChange={setPassword}
                                        type="password" />

                                    <TextInputTemplate
                                        value={repeatPassword}
                                        onChange={setRepeatPassword}
                                        label="Gentag Password"
                                        type="password" />
                                </div>
                                <div className={tableClasses.divRow}>
                                    <TextInputTemplate
                                        value={firstName}
                                        onChange={setFirstName}
                                        label="Fornavn" />
                                    <TextInputTemplate
                                        value={lastName}
                                        onChange={setLastName}
                                        label="Efternavn" />
                                </div>
                            </div>
                            <ThemeProvider theme={buttonTheme}>
                                <Button
                                    style={{ marginLeft: 25 }}
                                    className={buttonClasses.authButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={signUp}>
                                    Opret profil
                                </Button>

                            </ThemeProvider>
                        </TableContainer>
                    </form>
                    {/* )} */}
                    {/* {signUpView === 2 && (
                        <form noValidate>
                            <TableContainer
                                style={{ backgroundColor: 'rgba(252,252,252,0.6)' }}
                                className={tableClasses.table}
                                component={Paper}>
                                <div className={tableClasses.divInputs}>
                                    <TextInputTemplate
                                        value={username}
                                        onChange={setUsername}
                                        label="Brugernavn"/>
                                    <div className={tableClasses.divRow}>
                                        <TextInputTemplate
                                            value={firstName}
                                            onChange={setFirstName}
                                            label="Fornavn"/>
                                        <TextInputTemplate
                                            value={lastName}
                                            onChange={setLastName}
                                            label="Efternavn"/>
                                    </div>
                                </div>
                                <ThemeProvider theme={buttonTheme}>
                                    <Button
                                        style={{ marginLeft: 25 }}
                                        className={buttonClasses.authButton}
                                        variant="contained"
                                        color="primary"
                                        onClick={signUpWithUniLogin}>
                                        Opret profil
                                    </Button>

                                </ThemeProvider>
                            </TableContainer>
                        </form>
                    )} */}

                </Body>
            </div>
        </BackgroundWithImage>
    )
}
