import { Hidden, IconButton } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import { Auth, Hub } from 'aws-amplify'
import React from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { LogoutModal } from '../molecules/LogoutModal'

interface Props {
    handleDrawerToggle: () => void
}

export const NavBar: React.FC<Props> = ({ handleDrawerToggle }) => {
    const classes = buttonStyles()

    const [logout, setLogout] = React.useState(false)
    const [loggingOut, setLoggingOut] = React.useState(false)

    const handleLogout = async () => {
        setLoggingOut(true)

        await Auth.signOut()

        setLoggingOut(false)
        // window.location.href = 'https://sso.emu.dk/logout'

        Hub.dispatch('auth', { event: 'signOut' })
    }
    return (
        <AppBar position="fixed" elevation={0} color="inherit">
            <Toolbar>
                <Hidden mdUp>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row' }}>
                    <Button
                        className={classes.logoutButton}
                        onClick={() => setLogout(true)}
                        color="secondary">Log ud
                    </Button>
                    <LogoutModal
                        disableAccept={loggingOut}
                        onConfirmAction={handleLogout}
                        onClose={() => setLogout(true)}
                        onCancelAction={() => setLogout(false)}
                        isOpen={logout}
                        headerText="Er du sikker på at du vil logge ud?"
                        confirmActionButtonText="Ja, helt sikker"
                        cancelActionButtonText="Nej" />
                </div>
            </Toolbar>
        </AppBar>
    )
}
