import { makeStyles } from '@material-ui/core'
import { colorify } from 'lottie-colorify'
import React from 'react'
import Lottie from 'react-lottie'

import animationData from '../../assets/confetti.json'

const useStyles = makeStyles(() => ({
    confetti: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transform: 'translateY(-180px)',
        textAlign: 'center',
        zIndex: 9999,
    }
}))

export const ConfettiContainer: React.FC = () => {
    const classes = useStyles()
    const [first, setFirst] = React.useState('')
    const [second, setSecond] = React.useState('')
    const [third, setThird] = React.useState('')
    const [fourth, setFourth] = React.useState('')

    function getRandomColor() {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i += 1) {

            color += letters[Math.floor(Math.random() * 16)]
        }
        return color
    }
    React.useEffect(() => {
        setFirst(getRandomColor())
        setSecond(getRandomColor())
        setThird(getRandomColor())
        setFourth(getRandomColor())
    }, [])

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: colorify([
            ...Array(5).fill(first),
            ...Array(6).fill(second),
            ...Array(5).fill(third),
            ...Array(5).fill(fourth),
            ...Array(5).fill(first),
            ...Array(5).fill(second),
            ...Array(6).fill(third),
            ...Array(5).fill(fourth),

        ], animationData),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    return (
        <div
            className={classes.confetti}
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
            <Lottie
                options={defaultOptions}
                height={500}
                width={500} />
        </div>
    )
}
