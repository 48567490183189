import { Box, Button } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import React from 'react'

import { MainCard } from '../../components/molecules/MainCard'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    content: {
        justifyContent: 'center'
    }
}))

export function Games() {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState<string | false>(false)
    const [isFullScreen, setIsFullScreen] = React.useState(false)

    const handleChange = (panel: any) => (_event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false)
    }

    return ( // @ts-ignore
        <BodyWithTopBar display={!isFullScreen}>
            <MainCard
                icon={<SportsEsportsIcon />}
                isGameScreen
                title="Spil">
                <div className={classes.root}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            classes={{ content: classes.content }}>
                            <Typography variant="h4">Math or Splat!</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                <iframe
                                    title="Math or Splat!"
                                    src="https://i.simmer.io/@GeeksterDK/math-or-splat"
                                    style={isFullScreen ? {
                                        overflow: 'hidden',
                                        height: '100%',
                                        width: '100%',
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        zIndex: 9
                                    } : {
                                        width: 960,
                                        height: 600,
                                        marginRight: 'auto',
                                        marginLeft: 'auto'
                                    }} />
                            </Box>
                            {isFullScreen ? (
                                <Button
                                    onClick={() => setIsFullScreen(!isFullScreen)}
                                    style={{
                                        background: '#fff',
                                        position: 'fixed',
                                        zIndex: 10,
                                        top: 60,
                                        right: 20
                                    }}>
                                    Forlad fuld skærm
                                </Button>
                            )
                                : <Button onClick={() => setIsFullScreen(!isFullScreen)}>Fuld skærm</Button>}

                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            classes={{ content: classes.content }}>
                            <Typography variant="h4">Verdens Sværeste Brøk-Spil!</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                <iframe
                                    title="Verdens Sværeste Brøk-spil"
                                    src="https://i.simmer.io/@GeeksterDK/~7e3df0f2-4750-b62e-9393-2a8a0dad1b26"
                                    style={isFullScreen ? {
                                        overflow: 'hidden',
                                        height: '100%',
                                        width: '100%',
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        zIndex: 9
                                    } : {
                                        width: 960,
                                        height: 600,
                                        marginRight: 'auto',
                                        marginLeft: 'auto'
                                    }} />
                            </Box>
                            {isFullScreen ? (
                                <Button
                                    onClick={() => setIsFullScreen(!isFullScreen)}
                                    style={{
                                        background: '#fff',
                                        position: 'fixed',
                                        zIndex: 10,
                                        top: 60,
                                        right: 20
                                    }}>
                                    Forlad fuld skærm
                                </Button>
                            )
                                : <Button onClick={() => setIsFullScreen(!isFullScreen)}>Fuld skærm</Button>}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            classes={{ content: classes.content }}>
                            <Typography variant="h4">Tal-tovtrækning</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box
                                flexDirection="column"
                                display="flex"
                                marginX="auto">
                                <Typography align="center" style={{ color: '#03a9f4', fontSize: 20 }}>
                                    Som vært for et spil må du ikke forlade denne side ellers kan du ikke starte spillet
                                </Typography>
                                <iframe
                                    title="Tal-tovtrækning"
                                    src="https://i.simmer.io/@GeeksterDK/~1291e235-8d74-15f4-21a9-a73817cbf6ab"
                                    style={isFullScreen ? {
                                        overflow: 'hidden',
                                        height: '100%',
                                        width: '100%',
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        zIndex: 9
                                    } : {
                                        width: 960,
                                        height: 600,
                                        marginRight: 'auto',
                                        marginLeft: 'auto'
                                    }} />
                                <Typography align="center" style={{ color: '#03a9f4', fontSize: 20 }}>
                                    Andre kan deltage i tovtrækning, uden login her: {' '}
                                    <a
                                        style={{ color: '#03a9f4' }}
                                        href="https://tov.geekster.dk"
                                        target="_blank"
                                        rel="noreferrer">
                                        tov.geekster.dk
                                    </a>
                                </Typography>
                            </Box>
                            {isFullScreen ? (
                                <Button
                                    onClick={() => setIsFullScreen(!isFullScreen)}
                                    style={{
                                        background: '#fff',
                                        position: 'fixed',
                                        zIndex: 10,
                                        top: 60,
                                        right: 20
                                    }}>
                                    Forlad fuld skærm
                                </Button>
                            )
                                : <Button onClick={() => setIsFullScreen(!isFullScreen)}>Fuld skærm</Button>}
                        </AccordionDetails>
                    </Accordion>
                    <Typography
                        style={{ fontSize: 26 }}
                        align="center">Flere matematikspil er på vej!
                    </Typography>

                </div>
            </MainCard>
        </BodyWithTopBar>
    )
}
