import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Auth } from 'aws-amplify'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { wrap } from '../../utils/wrapers'
import { CustomButton } from '../atoms/CustomButton'

export const PasswordForm: React.FC = () => {
    const [oldPassword, setOldPassword] = React.useState('')
    const [newPassword, setNewPassword] = React.useState('')
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('')
    const [busy, setBusy] = React.useState(false)

    const history = useHistory()

    const validateForm = () => {
        return (
            oldPassword.length > 5
            || newPassword.length > 5
            || newPassword === confirmNewPassword
        )

    }

    const resetPassword = async () => {
        await wrap(async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser()
                await Auth.changePassword(
                    currentUser,
                    oldPassword,
                    newPassword
                )
                history.push('/lektiers')
            } catch (error) {
                // ignore
            }

        }, {
            operation: 'Nulstilling af adgangskode',
            showSuccess: true,
            stateFunc: setBusy
        })
    }
    return (
        <Box width={300} p={2} mb={5} m="auto">
            <Typography variant="h6" gutterBottom>Opdater din adgangskode</Typography>
            <TextField
                variant="outlined"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                label="Gammelt Kodeord"
                fullWidth
                type="password"
                color="primary"
                margin="normal"/>
            <TextField
                variant="outlined"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                label="nyt kodeord"
                fullWidth
                type="password"
                color="primary"
                margin="normal"/>
            <TextField
                variant="outlined"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                label="Bekræft ny adgangskode"
                fullWidth
                type="password"
                color="primary"
                margin="normal"/>
            <Box mt={2}>
                <CustomButton
                    onClick={() => resetPassword()}
                    variant="contained"
                    disabled={!validateForm}
                    loading={busy}
                    color="primary"
                    fullWidth>
                    Opdater kode
                </CustomButton>
            </Box>
            <Box mt={1}>
                <Typography variant="caption">Har du problemer? Skriv til info@geekster.dk
                </Typography>
            </Box>
        </Box>
    )
}
