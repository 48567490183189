import * as React from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'

import { primaryLightContrast } from '../../utils/colors'

export const FixedLoading = styled.div<{}>`
    background: ${primaryLightContrast};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
`

export const Loading: React.FC = () => {
    const color1 = '#4DAF7C'
    const type = 'cylon'

    // @ts-ignore
    return (
        <FixedLoading>
            <div style={{
                display: 'flex', justifyContent: 'center', width: '100%', height: '60%', alignItems: 'center' 
            }}>
                <div>
                    <ReactLoading type={type} color={color1} height={100} width={100} />
                </div>

            </div>
        </FixedLoading>
    )
}
