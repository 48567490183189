import { Container, Typography } from '@material-ui/core'
import React from 'react'

import { homeworkStyles } from '../../styles/HomeworkStyle'
import { isQuizUpdated } from '../../utils/helpers'
import { AssignedQuizType } from '../../utils/types'
import { QuizTag } from './QuizUpdatedTag'

interface Props {
  quiz: AssignedQuizType
}

export const QuizHeader: React.FC<Props> = ({
    quiz
}) => {
    const classesHomework = homeworkStyles()

    return (
        <>
            <div style={{
                alignItems: 'center',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Typography
                    style={{
                        paddingLeft: isQuizUpdated(quiz) ? '15px' : 0,
                        fontWeight: 'bold'
                    }}
                    className={classesHomework.heading}>
                    {quiz.title}
                </Typography>
                {isQuizUpdated(quiz) && <QuizTag />}
            </div>
            {quiz?.file?.uri && (
                <Container className={classesHomework.wrap}>
                    <a
                        color="primary"
                        className={classesHomework.anchor}
                        href={quiz?.file?.uri}>
                        Download tilhørende PDF
                    </a>
                </Container>
            )}
        </>
    )
}
