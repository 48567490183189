import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { SvgIconComponent } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles(() => createStyles({
    cardRoot: {
        minHeight: 250,
        minWidth: 300,
        maxWidth: 850,
        margin: 'auto',
        padding: '20px 30px',
        borderRadius: 25,
        boxShadow: '0 -2px blue'
    }
}))

interface Props {
  icon?: React.ReactElement<SvgIconComponent>, 
  title?: string,
  upcomingHomeworkCount?: number
  showHomework?: boolean 
  isGameScreen?: boolean
}

export const MainCard: React.FC<Props> = ({
    children, 
    icon, 
    title,
    upcomingHomeworkCount,
    showHomework,
    isGameScreen = false
}) => {
    const classes = useStyles()

    return (
        <Card
            className={classes.cardRoot} 
            style={isGameScreen ? { maxWidth: 1100 } : {}}
            elevation={2}
            variant="outlined">
            {title && (
                <Box display="flex" flexDirection="row" alignItems="center" mb={3}>
                    {icon && <Box mr={3}>{icon}</Box>}
                    <Box>
                        {showHomework ? (
                            <>
                                <Typography variant="h6"><strong>{title}</strong></Typography>
                                <Typography variant="subtitle2">Du har {upcomingHomeworkCount} kommende lektier
                                </Typography>
                            </>
                        ) : (
                            <Typography variant="h6"><strong>{title}</strong></Typography>
                        )}
                       
                    </Box>
                </Box>
            )}
            {children}
        </Card>
    )
}
