import { API } from 'aws-amplify'

import { AnswerType, QuizResultType, SubmitQuizAnswerType } from '../types'

export const submitQuizAnswer = async (params: SubmitQuizAnswerType): Promise<AnswerType> => {
    return API.post('api', '/answers', {
        body: params
    })
}

export const submitAnonymousQuizAnswer = async (anonId: string, params: SubmitQuizAnswerType): Promise<AnswerType> => {
    return API.post('openapi', `/anon/${anonId}/answers`, {
        body: params
    })
}

export const getQuizResult = async (homeworkId: string, quizId: string): Promise<QuizResultType> => {
    return API.post('api', '/quizzes/result', {
        body: { homeworkId, quizId }
    })
}
