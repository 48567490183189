import ContactMailIcon from '@material-ui/icons/ContactMail'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import SettingsIcon from '@material-ui/icons/Settings'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import * as React from 'react'

type Route = {
    path: string;
    name: string;
    icon?: JSX.Element;
    secure: boolean;
}

const Login: Route = {
    path: '/login',
    name: 'Log in',
    secure: false
}
const SignUp: Route = {
    path: '/signup',
    name: 'Sign Up',
    secure: false
}

const Lessons: Route = {
    path: '/lektier',
    name: 'Lektier',
    icon: <ContactMailIcon />,
    secure: true
}

const Lesson: Route = {
    path: '/lektie',
    name: 'Lektie',
    icon: <ContactMailIcon />,
    secure: true
}

const Games: Route = {
    path: '/spil',
    name: 'Spil',
    icon: <SportsEsportsIcon />,
    secure: true
}

const Quiz: Route = {
    path: '/quiz',
    name: 'Quizværktøj',
    icon: <QuestionAnswerIcon />,
    secure: true
}

const StudentQuiz: Route = {
    path: '/quiz/student',
    name: 'Community quiz Item',
    secure: true
}

const Settings: Route = {
    path: '/indstillinger',
    name: 'Indstillinger',
    icon: <SettingsIcon />,
    secure: true
}

const CompleteSignUpWithUniLogin: Route = {
    path: '/signUp/complete',
    name: 'CompleteSignUpWithUniLogin',
    secure: false
}

const CompleteLogInWithUniLogin: Route = {
    path: '/login/complete',
    name: 'CompleteLogInWithUniLogin',
    secure: false
}

export const StudentDrawerRoutes = [Lessons, Quiz, Settings, Games]

export default {
    StudentQuiz,
    Quiz,
    Lessons,
    Settings,
    Login,
    Lesson,
    SignUp,
    CompleteSignUpWithUniLogin,
    CompleteLogInWithUniLogin,
    Games,
}
