/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/core/styles'

export const bodyStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: 0
    },
    content: {
        minHeight: '100vh',
        width: '100%',
        padding: '4%',
        marginTop: 50,
        position: 'relative'
    },
    bgImage: {
        maxWidth: 300,
        position: 'absolute',
        right: 30,
        bottom: 5,
        opacity: 0.7,
        zIndex: -3
    }
}))
