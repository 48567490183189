import '../../styles/alignmentTextRichTextEditor.css'
import '../../styles/style.css'

import {
    Grid, TableContainer, Typography
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import ScreeRotateIcon from '@material-ui/icons/ScreenRotation'
import * as React from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import Logo from '../../assets/logoText.png'
import { BottomBackgroundImage } from '../../components/atoms/BottomBackgroundImage'
import FAB from '../../components/atoms/Fab'
import { VideoItem } from '../../components/molecules/VideoItem'
import { QuizValidator } from '../../components/organisms/QuizValidator'
import { homeworkStyles } from '../../styles/HomeworkStyle'
import { pageStyles } from '../../styles/PageStyle'
import Routes from '../../utils/routes'
import { submitAnonymousQuizAnswer, submitQuizAnswer } from '../../utils/Services/answer-services'
import {
    createAnonymousIdentity,
    getAnonymousAssignedHomework,
    getAssignedHomework,
    getIsPublicHomework
} from '../../utils/Services/student-services'
import {
    AssignedHomeworkType, StudentType,
} from '../../utils/types'
import { fireWrap } from '../../utils/wrapers'
import { Loading } from '../Loading'

interface Props {
    student: StudentType | null
}
export const HomeworkItemPage: React.FC<Props> = ({ student }: Props) => {
    const classes = pageStyles()
    const history = useHistory()
    const classesHomework = homeworkStyles()
    const [homework, setHomework] = React.useState<AssignedHomeworkType>()
    const [busy, setBusy] = React.useState(false)
    const [isPublic, setIsPublic] = React.useState<boolean | null>(null)
    const [anon, setAnon] = React.useState<StudentType>()

    const { id }: { id: string | undefined } = useParams()
    if (!id) {
        return <Redirect to={Routes.Lessons.path} />
    }
    React.useEffect(() => {
        (async () => {
            setIsPublic(await getIsPublicHomework(id))
        })()
    }, [id])

    React.useEffect(() => {
        fireWrap(async () => {
            if (isPublic === null) {
                return
            }
            if (isPublic) {
                if (student) {
                    setHomework(
                        await getAssignedHomework(id)
                    )
                } else {
                    const anonStudent = await createAnonymousIdentity(id)
                    setAnon(anonStudent)
                    setHomework(
                        await getAnonymousAssignedHomework(anonStudent.id, id)
                    )
                }

            } else if (!student) {
                history.push('/login')
            } else {
                setHomework(await getAssignedHomework(id))
            }
        }, {
            operation: 'Loading Details',
            stateFunc: setBusy
        })
    }, [isPublic])

    const submitAnswer = async (quizId: string, questionIndex: number, answer: string, sharedUrl?: string) => {
        const payload = {
            homeworkId: homework!!.id,
            questionIndex,
            quizId,
            answer,
            sharedUrl
        }

        const checkedAnswer = await (anon ? submitAnonymousQuizAnswer(anon.id, payload) : submitQuizAnswer(payload))

        const quiz = homework!!.quizzes.find((q) => q.id === quizId)!!
        quiz.questions[questionIndex].givenAnswer = checkedAnswer

        return checkedAnswer
    }

    function showAnon() {
        if (anon) {
            return (
                <Typography variant="h6">Gæste-login: {anon.username}</Typography>
            )
        }

        return (
            <></>
        )
    }

    if (busy) {
        return (
            <Loading />
        )
    }

    return (
        <div className={classesHomework.paperContainer}>
            <Box style={{ width: '100%' }} height={85} display="flex" alignItems="center">
                <Box justifyContent="center" pl={2} mr={5}>
                    <img style={{ width: 60, height: 60 }} src={Logo} alt="Geekster Logo" />
                </Box>
                <Box justifyContent="center" mr={5}>
                    <Button
                        variant="contained"
                        startIcon={<ClearIcon />}
                        size="small"
                        onClick={() => history.push(Routes.Lessons.path)}>Forlad lektie
                    </Button>
                </Box>
                <FAB Icon={ScreeRotateIcon} />
            </Box>

            <TableContainer style={{ overflow: 'hidden' }} className={classes.table} component={Box}>
                {showAnon()}
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.gridContainer}>

                    {homework && homework.videos.sort((a, b) => (a.title > b.title ? 1 : -1)).map((v) => {
                        return (
                            <div key={v.id} className={classes.homeworkItem}>
                                <VideoItem v={v} />
                            </div>

                        )
                    })}
                    {homework && homework.quizzes.map((q) => {
                        return (
                            <div key={q?.id} className={classes.homeworkItem}>
                                <QuizValidator
                                    isPublic={isPublic}
                                    submitAnswerFunc={submitAnswer}
                                    quiz={q} />
                            </div>
                        )
                    })}
                    {homework && !homework.quizzes.length && !homework.videos.length && (
                        <Typography align="center" variant="h3">Lektier er tomme!</Typography>
                    )}
                </Grid>
            </TableContainer>
            <BottomBackgroundImage />
        </div>
    )
}
