import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import * as React from 'react'
import { useEffect, useState } from 'react'

import HomeEmoji from '../../assets/welcome-icon.png'
import { HomeworkRow } from '../../components/molecules/HomeworkRow'
import { MainCard } from '../../components/molecules/MainCard'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { getHomeworks } from '../../utils/Services/student-services'
import { AssignedHomeworkType, StudentType } from '../../utils/types'
import { fireWrap } from '../../utils/wrapers'
import { Loading } from '../Loading'

interface Props{
    student: StudentType|null
}

export const HomeworkPage: React.FC<Props> = ({ student, }:Props) => {
    const [busy, setBusy] = useState(false)
    const [homeworks, setHomeworks] = useState<AssignedHomeworkType[]>([])
    // const [student, setStudent] = React.useState<StudentType>()

    useEffect(() => {
        fireWrap(async () => {
            const fetchedHomeworks = await getHomeworks()
            fetchedHomeworks.sort((a, b) => (a.expirationDate > b.expirationDate ? -1 : 1))
            setHomeworks(fetchedHomeworks)
        }, {
            operation: 'Loading Data',
            stateFunc: setBusy
        })
    }, [])

    const notExpired = () => {
        return homeworks.filter((a) => !a.isExpired).length
    }

    const capitalize = (word: string | undefined) => {
        if (word) {
            return word[0].toUpperCase() + word.slice(1).toLowerCase()
        }
        return undefined
    }
    if (busy) {
        return (
            <Loading/>
        )
    }

    return (
        <BodyWithTopBar>
            <Box display="flex" flexDirection="row" justifyContent="center" mb={4}>
                <img style={{ width: 48, height: 48 }} src={HomeEmoji} alt="Home Icon" />
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    style={{ paddingLeft: 5 }}>Velkommen, {capitalize(student?.firstName)}
                </Typography>
            </Box>
            <MainCard
                showHomework
                icon={<ContactMailIcon />}
                title="Lektier"
                upcomingHomeworkCount={notExpired()}>
                <Box>
                    <Box>
                        <Table>
                            <colgroup>
                                <col style={{ width: '30%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '10%' }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Lektie navn</strong></TableCell>
                                    <TableCell align="left"><strong>Udløber</strong></TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {homeworks.filter((hw) => !hw.isExpired).map((hw) => (
                                    <HomeworkRow
                                        homework={hw}
                                        key={hw.id}/>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>

                    <Box pt={4}>
                        <Typography variant="h6" style={{ fontWeight: 600 }}>Gamle lektier</Typography>
                        <Table>
                            <colgroup>
                                <col style={{ width: '30%' }} />
                                <col style={{ width: '16%' }} />
                                <col style={{ width: '10%' }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Lektie navn</strong></TableCell>
                                    <TableCell align="left"><strong>Udløber</strong></TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {homeworks.filter((hw) => hw.isExpired).map((hw) => (
                                    <HomeworkRow homework={hw} key={hw.id} /> 
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </MainCard>
        </BodyWithTopBar>
    ) 
}
