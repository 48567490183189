import {
    createStyles, Drawer, Hidden, makeStyles, Theme
} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import clsx from 'clsx'
import React from 'react'

import HomeImage from '../../assets/homeImage.png'
import { bodyStyles } from '../../styles/BodyWrapperStyles'
import { NavBar } from '../organisms/NavBar'
import { SideBar } from '../organisms/SideBar'

export const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) => createStyles({
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 200,
        paddingTop: 10,
        height: '100%',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}))

interface Props {
    display?: boolean
    children: React.ReactNode
}

export const BodyWithTopBar: React.FC<Props> = ({ children, display = true }: Props) => {
    const bodyClasses = bodyStyles()
    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = React.useState(false)

    return (
        <div className={bodyClasses.root}>
            <CssBaseline />
            {display && (
                <>
                    <NavBar handleDrawerToggle={() => setMobileOpen(!mobileOpen)} />
                    <Hidden smDown mdUp={false}>
                        <Drawer variant="permanent" classes={{ paper: clsx(classes.drawerPaper) }} anchor="left">
                            <SideBar />
                        </Drawer>
                    </Hidden>
                </>
            )}
            <Hidden mdUp={false}>
                <Drawer
                    classes={{ paper: clsx(classes.drawerPaper) }}
                    open={mobileOpen}
                    variant="temporary"
                    onClose={() => setMobileOpen(!mobileOpen)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    anchor="left">
                    <SideBar />
                </Drawer>
            </Hidden>

            <main className={bodyClasses.content}>
                {children}
                <img className={bodyClasses.bgImage} src={HomeImage} alt="People and numbers" />

            </main>
        </div>
    )
}
BodyWithTopBar.defaultProps = {
    display: true,
}
