import { makeStyles } from '@material-ui/core/styles'

export const modalStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 0
    },
    backDrop: {
        background: 'rgba(140,34,34,0.2)',
    },
    paper: {
        position: 'absolute',
        outline: 0,
        width: 450,
        backgroundColor: '#F1ECE8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    login: {
        borderRadius: 10,
        position: 'absolute',
        zIndex: 3,
        outline: 0,
        fontFamily: 'Encode, sans-serif',
        width: '61vw',
        backgroundColor: '#ffffff',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paperBigger: {
        position: 'absolute',
        outline: 0,
        width: 1000,
        backgroundColor: '#F1ECE8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    FullPage: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
        position: 'absolute',
        outline: 0,
        backgroundColor: '#f6c6a2',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))
