import {
    Box, Button, createStyles, makeStyles, Modal, Typography
} from '@material-ui/core'
import React from 'react'

import { StudentQuizType } from '../../utils/types'
import { drawerWidth } from '../wrappers/BodyWithTopBar'

interface ReasonProps {
    openReasonModal: boolean,
    setOpenReasonModal: (openReasonModal: boolean) => void,
    selected: StudentQuizType | undefined
}
const useStyles = makeStyles((currentTheme) => createStyles({
    modal: {
        [currentTheme.breakpoints.down('sm')]: {
            marginLeft: 0
        },
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        backgroundColor: 'white',
        border: '2px solid #000',
        marginLeft: drawerWidth / 2,
        padding: 20,
    },
}))

export const ReasonModal: React.FC<ReasonProps> = ({ openReasonModal, setOpenReasonModal, selected }) => {
    const classes = useStyles()
    return (
        <Modal
            open={openReasonModal}
            onClose={() => setOpenReasonModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={classes.modal}>
                <Typography variant="h5">Afviste felter: </Typography>
                <Typography>{selected?.notApprovedFields.join(', ')}</Typography>
                <br />
                <Typography variant="h5">Grund: </Typography>
                <Typography>{selected?.feedbackNote}</Typography>
                <br />
                <Typography>
                    <b>
                        For at gøre din quiz tilgængelig til andre igen,
                        opdateres de problematiske felter.
                    </b>
                </Typography>
                <br />
                <Button variant="contained" onClick={() => setOpenReasonModal(false)}>
                    Bekræfte
                </Button>
            </Box>
        </Modal>
    )
}
