// @ts-ignore
import qs from 'query-string'
import { useLocation } from 'react-router-dom'

export interface UniLoginReply {
    user: string
    timestamp: string
    auth: string
}

export function useUniLoginReply<T extends UniLoginReply>(): T | undefined {
    const { search } = useLocation()
    const ps = qs.parse(search) as T

    if (!ps.user || !ps.timestamp || !ps.auth) {
        return undefined
    }

    return ps
}
