import { Box, } from '@material-ui/core'
import React from 'react'

import { TextInputTemplate } from './TextInputTemplate'

type Props = {
    underText: string
    label: string
    answers: string[]
    onChange?(tags: string[]): void
}

export const MultipleAnswers: React.FC<Props> = ({
    underText, label, onChange, answers: asw
}) => {

    const [answers, setAnswers] = React.useState(asw)
    const [value, setValue] = React.useState('')

    const handleTagInputKeyPress = (key: any, e: React.KeyboardEvent) => {

        if (key.toString() === 'Enter' && value.length > 0) {

            if (!answers.map((answer) => answer.toLowerCase()).includes(value.trim().toLowerCase())) {
                const newAnswers = [...answers, value]
                setAnswers(newAnswers)
                if (onChange) {
                    onChange(newAnswers)
                }
            }

            setValue('')
            e.preventDefault()
        }
    }

    const removeAnswer = (t: string) => {
        const filteredAnswers = answers.filter((tt: string) => tt !== t)
        setAnswers(filteredAnswers)
        if (onChange) {
            onChange(filteredAnswers)
        }
    }
    
    const typedAnswers = answers.map((t: string) => (
        <Box
            key={t}
            style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 3
            }}>
            <p style={{
                display: 'flex',
                flexDirection: 'row',
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: '#485058',
                fontSize: 12,
                fontWeight: 300,
                marginBottom: 5,
                padding: 4,
                borderRadius: 5
            }}>{t}
                <button type="button" onClick={() => removeAnswer(t)}>x</button>
            </p>
        </Box>
       
    ))

    return (
        <>
            <TextInputTemplate
                fullWidth
                value={value}
                onChange={setValue}
                onKeyPress={handleTagInputKeyPress}
                underText={underText}
                label={label}
                type="default"/>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                { typedAnswers }
            </div>
           
        </>
    )
}
