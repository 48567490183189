import { 
    FormControl, InputLabel, MenuItem, Select 
} from '@material-ui/core'
import * as React from 'react'

interface Props {
    value: string[],
    label: string,
    possibleValues: string[],
    setValue: (values: string[]) => void,
    small?: boolean,
    onChange?: (event:any) => void
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
    getContentAnchorEl: null,
}

export const BasicMultipleSelect: React.FC<Props> = ({
    value, label, possibleValues, setValue, small, onChange
}) => {
    const handleChange = (event: any) => {
        if (event.target.value.length === 0) setValue([])
        setValue(event.target.value)
    }
    return (
        <FormControl style={{ marginBottom: 15, width: small ? '75%' : '100%' }}>
            <InputLabel id="demo-multiple-name-label">{label}</InputLabel>     
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={value}
                multiple
                onChange={onChange || handleChange}
                MenuProps={MenuProps}>
                {possibleValues.map((ct) => (
                    <MenuItem
                        key={ct}
                        value={ct}>
                        {ct}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
