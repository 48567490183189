/* eslint-disable no-nested-ternary */
import 'katex/dist/katex.min.css'
import '../../styles/katex.css'

import { Button, Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Howl } from 'howler'
import * as React from 'react'
import { useEffect, useState } from 'react'
import DropboxChooser from 'react-dropbox-chooser'
import { addStyles, EditableMathField, StaticMathField } from 'react-mathquill'
import ReactPlayer from 'react-player'

// import useSound from 'use-sound'
import rightAnswer from '../../assets/rightanswericon.png'
import wrongAnswer from '../../assets/wronganswericon.png'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { homeworkStyles } from '../../styles/HomeworkStyle'
import { useQuestionStyles } from '../../styles/QuestionStyles'
import { getRandomSounds } from '../../utils/Services/sounds-service'
import { AnswerType, AssignedQuestionType, RandomSoundsType } from '../../utils/types'
import { CustomButton } from '../atoms/CustomButton'
import { ConfettiContainer } from '../atoms/QuestionCorrectConfetti'
import { SpaceBetween } from '../atoms/SpaceBetween'

interface Props {
    question: AssignedQuestionType,
    answer: AnswerType | undefined,
    disabled?: boolean,
    canGoPrevious: boolean,
    canGoNext: boolean,
    isQuizOpen: boolean,
    onGoPrevious(): void
    onGoNext(): void,
    onSubmit(answer: string, sharedUrl?: string): Promise<boolean>,
    videoLoading: boolean,
    setVideoLoading: (videoLoading: boolean) => void
    confetti: boolean
    hasAudio: boolean
    isAnswered: boolean
    setIsAnswered: (value: boolean) => void
}

const DROPBOX_KEY = 'ls6h9p3q5pia1x2'

addStyles()

export const QuestionValidator: React.FC<Props> = ({
    confetti, hasAudio,
    question, onGoPrevious, onGoNext, isQuizOpen, videoLoading, setVideoLoading,
    canGoPrevious, canGoNext, onSubmit, disabled, answer, isAnswered, setIsAnswered
}) => {
    const [changeAnswer, setChangeAnswer] = useState(false)
    const btnClasses = buttonStyles()
    const homeWorkClasses = homeworkStyles()
    const [textAnswer, setTextAnswer] = useState('')
    const [validated, setValidated] = useState(false)
    const [answerCorrect, setAnswerCorrect] = useState<boolean>(false)
    const [soundCorrectAnswer, setSoundCorrectAnswer] = useState<Howl>({} as Howl)
    const [soundWrongAnswer, setSoundWrongAnswer] = useState<Howl>({} as Howl)
    const [randomSounds, setRandomSounds] = React.useState<RandomSoundsType>({} as RandomSoundsType)
    const [documentPreview, setDocumentPreview] = useState('')
    const [sharedUrl, setSharedUrl] = useState('')

    React.useEffect(() => {
        if (Object.keys(randomSounds).length) {
            setSoundCorrectAnswer(new Howl({
                src: String(randomSounds.positiveFeedback.soundFile?.uri),
                html5: true,
            }))
        }
    }, [randomSounds])

    React.useEffect(() => {
        if (Object.keys(randomSounds).length) {
            setSoundWrongAnswer(new Howl({
                src: String(randomSounds.negativeFeedback.soundFile?.uri),
                html5: true,
            }))
        }
    }, [randomSounds])
    React.useEffect(() => {
        (async () => {
            setRandomSounds(await getRandomSounds())
        })()
    }, [question])

    const classes = useQuestionStyles()

    useEffect(() => {
        setTextAnswer(answer ? answer.answer : '')
        setValidated(answer ? !!answer.answer : false)
        setAnswerCorrect(answer ? answer.correct : false)
    }, [question])

    // const [playErrorSound] = useSound('human.mp3', {
    //     playbackRate: 2,
    //     volume: 1,
    // })

    const isNextDisabled = () => {
        if (videoLoading) return true
        if (isQuizOpen) return false
        else {
            // 'next' button isn't disabled if the question is answered successfully
            if (answerCorrect && canGoNext) return false
            return true
        }
    }
    const validateAnswer = async () => {
        setIsAnswered(true)
        const isCorrect = await onSubmit(textAnswer.toLowerCase().trim(), sharedUrl || question.givenAnswer?.sharedUrl)
        setValidated(true)
        if (isCorrect && hasAudio && !question?.openQuestion) {
            soundCorrectAnswer.play()
        } else if (hasAudio && !question?.openQuestion) {
            soundWrongAnswer.play()
        }
        setChangeAnswer(false)
        setDocumentPreview('')
        setSharedUrl('')
        setAnswerCorrect(isCorrect)
    }

    // eslint-disable-next-line no-undef-init
    let feedback: JSX.Element | undefined
    if (validated) {
        feedback = (
            <Box style={{ paddingTop: 10 }} justifyContent="center" display="flex" alignItems="center" mt={0.5}>
                <img
                    className={classes.image}
                    src={answerCorrect ? rightAnswer : wrongAnswer}
                    alt={answerCorrect ? 'Right Icon' : 'Wrong Icon'} />
                <Typography component="span">
                    {answerCorrect ? (
                        question.openQuestion ? 'Din besvarelse er modtaget' : 'Godt gået, dit svar er korrekt!'

                    ) : 'Desværre forkert svar, prøv igen'}
                </Typography>
            </Box>
        )
    }

    function handleSuccess(files: any[]) {
        setDocumentPreview(files[0].thumbnailLink || files[0].icon)
        setSharedUrl(files[0].link)
    }

    return (
        <Grid
            container
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                fontFamily: 'Encode, sans-serif'
            }}>
            {question.explanation.length > 1 && (
                <div
                    // style={{
                    //     width: '100%',
                    //     display: 'flex',
                    //     marginBottom: 5,
                    //     justifyContent: 'center',
                    //     fontSize: '14px',
                    //     alignItems: 'center',
                    //     textAlign: 'center',
                    //     flexWrap: 'wrap',
                    //     overflow: 'hidden'
                    // }}
                    dangerouslySetInnerHTML={
                        { __html: question.explanation as string }
                    } />
            )}

            <>
                {question.picture?.uri && (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: 30,
                        }}>

                        <img
                            className={homeWorkClasses.imageQuestion}
                            style={{
                                maxWidth: 700,
                                maxHeight: 700,

                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}
                            alt="question"
                            src={question.picture.uri} />

                    </div>
                )}
            </>
            {(question.vimeoVideoId || question.youtubeVideoId) && (
                <ReactPlayer
                    url={
                        question.vimeoVideoId
                            ? `https://player.vimeo.com/video/${question.vimeoVideoId}`
                            : `https://www.youtube.com/watch?v=${question.youtubeVideoId}`
                    }
                    width="90%"
                    onReady={() => setVideoLoading(false)}
                    onError={() => setVideoLoading(false)}
                    controls />
            )}

            <div
                style={{
                    width: '100%'
                }}>
                {answerCorrect && confetti && isAnswered && (
                    <div style={{
                        left: '50%',
                        transform: 'translateX(-50%)',
                        position: 'relative'
                    }}>{isAnswered}
                        <ConfettiContainer />
                    </div>
                )}
                {feedback}
                <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 15 }}>
                    <Grid item md={4} sm={12} xs={12} style={{ textAlign: 'center' }}>
                        {canGoPrevious ? (
                            <Button
                                className={btnClasses.nextForward}
                                disabled={!canGoPrevious || videoLoading}
                                onClick={() => {
                                    onGoPrevious()
                                    setIsAnswered(false)
                                    setChangeAnswer(false)
                                    setDocumentPreview('')
                                    setSharedUrl('')
                                }}
                                startIcon={<ArrowBackIosIcon />}
                                variant="contained">Forrige
                            </Button>
                        ) : <Box />}
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} style={{ textAlign: 'center' }}>
                        {disabled ? <Typography>Tjekker dit svar ...</Typography> : (
                            question.openQuestion ? (
                                <TextField
                                    variant="outlined"
                                    value={textAnswer}
                                    onChange={(e) => setTextAnswer(e.target.value)}
                                    // onKeyPress={(e) => { if (e.key === 'Enter') validateAnswer() }}
                                    label="Svar..."
                                    color="primary"
                                    style={{
                                        width: '100%',
                                    }}
                                    disabled={!changeAnswer && validated && answerCorrect}
                                    multiline
                                    minRows={question.openQuestion ? 4 : 1}
                                    maxRows={4} />
                            ) : (
                                validated && answerCorrect ? (
                                    <StaticMathField
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            padding: '10px',
                                            fontSize: '20px'
                                        }}>
                                        {textAnswer}
                                    </StaticMathField>
                                ) : (
                                    <EditableMathField
                                        latex={textAnswer}
                                        style={{
                                            width: '12.5vw',
                                            height: 'auto',
                                            padding: '10px',
                                            fontSize: '20px'
                                        }}
                                        placeholder="Svar"
                                        onChange={(mathField) => {
                                            setTextAnswer(mathField.latex())
                                        }}
                                        onKeyPress={(e) => { if (e.key === 'Enter') validateAnswer() }}/>
                                ))
                        )}
                        {
                            // eslint-disable-next-line no-nested-ternary
                            ((question.openQuestion 
                                && question.givenAnswer?.sharedUrl) || changeAnswer) 
                                ? ((!question.givenAnswer)
                            || changeAnswer) 
                                    ? (
                                        <>
                                            <DropboxChooser
                                                appKey={DROPBOX_KEY}
                                                success={handleSuccess}
                                                cancel={() => { }}>

                                                <Button
                                                    className={btnClasses.dropBoxButton}
                                                    style={{
                                                        marginTop: 15,
                                                    }}
                                                    color="primary"
                                                    variant="contained">
                                                    Upload eller vælg filer
                                                </Button>

                                                <div className="dropbox" />
                                                {documentPreview && (
                                                    <>
                                                        <h4 style={{ textAlign: 'center', color: '#036b85' }}>
                                                            Forhåndsvisning af upload:
                                                        </h4>
                                                        <img src={documentPreview} width="100" height="100" alt="" />
                                                    </>
                                                )}

                                            </DropboxChooser>
                                        </>
                                    ) 
                                    : (
                                        <TextField
                                            style={{ marginTop: 15 }}
                                            variant="outlined"
                                            value={question.givenAnswer?.sharedUrl}
                                            disabled
                                            label="Delt fil..."
                                            color="primary"
                                            size="medium" />
                                    )
                                : null

                        }
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} style={{ textAlign: 'center' }}>
                        {canGoNext ? (
                            <Button
                                className={btnClasses.nextForward}
                                disabled={isNextDisabled()}
                                onClick={() => {
                                    onGoNext()
                                    setIsAnswered(false)
                                    setChangeAnswer(false)
                                    setDocumentPreview('')
                                    setSharedUrl('')
                                }}
                                endIcon={<ArrowForwardIosIcon />}
                                variant="contained">Næste
                            </Button>
                        ) : <Box />}
                    </Grid>
                </Grid>

                <SpaceBetween style={{ marginTop: 15, marginBottom: 15, justifyContent: 'center' }}>
                    <CustomButton
                        style={{
                            backgroundColor: '#6fd995', fontWeight: 600, height: 40, padding: 5, width: '12.5vw'
                        }}
                        variant="contained"
                        disabled={textAnswer.toLowerCase().trim().length < 1
                            || (!question.openQuestion && validated && answerCorrect) || disabled}
                        loading={disabled}
                        size="medium"
                        color="primary"
                        onClick={() => (
                            (!changeAnswer && validated && answerCorrect) ? setChangeAnswer(true) : validateAnswer()
                        )}>
                        {question.openQuestion
                            ? (!changeAnswer && validated && answerCorrect) ? 'Skift svar' : 'Tilføj' : 'Svar'}
                    </CustomButton>
                </SpaceBetween>
            </div>
        </Grid>
    )
}
