import { Checkbox, Link } from '@material-ui/core'
import * as React from 'react' 

interface Props {
    acceptedTaC: boolean,
    setAcceptedTaC: (accepted: boolean) => void
}

const TaCCheck: React.FC<Props> = ({
    acceptedTaC,
    setAcceptedTaC,
}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
                color="primary"
                checked={acceptedTaC}
                onChange={(e) => setAcceptedTaC(e.target.checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}/>
            <Link target="_blank" href="/tac">Jeg er enig i Geekster.dk*</Link>
        </div>
    )
}
export default TaCCheck
