import { Paper, TableContainer } from '@material-ui/core'
import * as React from 'react'
import { useAsync } from 'react-async-hook'

import { Loading } from '../../content/Loading'
import { useLoadingManager } from '../../utils/hooks'
import {
    deleteCommunityQuiz,
    finishCreatingCommunityQuiz,
    getStudentQuizById,
    listStudentQuizzes,
    updateCommunityQuizQuestions
} from '../../utils/Services/community-quiz-service'
// import { getQuizzes } from '../../utils/Services/student-services'
import { useTableStyles } from '../../utils/styles'
import { FileType, Question } from '../../utils/types'
import Alert from '../atoms/Alert'
import ConfirmDialog from '../atoms/ConfirmDialog'
import FormButtons from '../atoms/FormButtons'
import GoBackHeader from '../atoms/GoBackHeader'
import { QuestionModal } from '../atoms/QuestionModal'
import { SecondCreateCommunityQuizStep } from '../molecules/SecondCreateCommunityQuizStep'

interface Props {
    setFinishCreatingId: (id: string) => void,
    onQuizCreate: () => void,
    createdQuizId: string,
    justUpdateQuestions: boolean,
    description: string,
    setDescription: (description: string) => void
}

const SecondCreateCommunityQuizScreen: React.FC<Props> = ({
    setFinishCreatingId, createdQuizId, justUpdateQuestions, onQuizCreate,
    description, setDescription
}) => {
    const fetchStudentQuizzes = useAsync(listStudentQuizzes, [])
    const { loading, startLoading, stopLoading } = useLoadingManager()

    const [questions, setQuestions] = React.useState<Question[]>([])
    const [file, setFile] = React.useState<FileType>()
    const [acceptedTaC, setAcceptedTaC] = React.useState<boolean>(false)

    const [openModal, setOpenModal] = React.useState<boolean>(false)
    const [createError, setCreateError] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)

    React.useEffect(() => {
        (async () => {
            if (createdQuizId) {
                startLoading()
                const fetchedQuiz = await getStudentQuizById(createdQuizId)
                if (fetchedQuiz) setQuestions(fetchedQuiz.questions || [])
                stopLoading()
            }
        })()
    }, [])

    const handleGoBack = () => {
        onQuizCreate()
        setFinishCreatingId('')
    }
    const onCreate = async () => {
        startLoading()
        try {
            await finishCreatingCommunityQuiz(createdQuizId, description, questions, file)
            handleGoBack()
        } catch (err) {
            // TODO: should be generalized 
            setErrorMessage(err.message)
            setCreateError(true)
        }
        stopLoading()
    }

    const onQuestionUpdate = async () => {
        startLoading()
        try {
            await updateCommunityQuizQuestions(createdQuizId, questions)
            handleGoBack()
        } catch (err) {
            // TODO: should be generalized 
            setErrorMessage(err.message)
            setCreateError(true)
        }
        stopLoading()
    }
    const validateForm = () => {
        if (description.length > 0) return true
        return false
    }
    const onDelete = async () => {
        startLoading()
        await deleteCommunityQuiz(createdQuizId)
        fetchStudentQuizzes.execute()
        handleGoBack()
        stopLoading()
    }
    const onDeleteButtonClick = () => {
        setConfirmDelete(true)
    }
    const disableButtons = () => {
        if (justUpdateQuestions) return false
        if (!validateForm() || !acceptedTaC) return true
        return false
    }
    const tableClasses = useTableStyles()

    if (loading) {
        return <Loading />
    }
    return (
        <form noValidate>
            <TableContainer className={tableClasses.table} component={Paper}>
                <GoBackHeader
                    headerText={justUpdateQuestions
                        ? 'Opdater spørgsmål'
                        : ' Tilføj spørgsmål og opret quiz'}
                    buttonText={justUpdateQuestions
                        ? 'Min quiz'
                        : 'Mine quizzer'}
                    onClick={handleGoBack} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    {!loading && (
                        <SecondCreateCommunityQuizStep
                            justUpdateQuestions={justUpdateQuestions}
                            description={description}
                            setDescription={setDescription}
                            questions={questions}
                            setQuestions={setQuestions}
                            setModalOpen={setOpenModal}
                            file={file}
                            setFile={setFile}
                            acceptedTaC={acceptedTaC}
                            setAcceptedTaC={setAcceptedTaC} />
                    )}
                    {!loading && (
                        <QuestionModal
                            questions={questions}
                            setQuestions={setQuestions}
                            quizId={createdQuizId}
                            openModal={openModal}
                            setOpenModal={() => setOpenModal(false)}
                            type="create"
                            title=""
                            explanation=""
                            vimeoVideoId=""
                            youtubeVideoId=""
                            pictureFile={undefined}
                            responses={[]} />
                    )}
                    <Alert
                        text={errorMessage}
                        setOpen={setCreateError}
                        open={createError}
                        type="alert" />
                    <ConfirmDialog
                        text="Er du sikker på at du vil slette denne quiz?"
                        open={confirmDelete}
                        onCancel={() => setConfirmDelete(false)}
                        onConfirm={onDelete} />
                </div>
            </TableContainer>
            <FormButtons
                createButtonText={justUpdateQuestions ? 'Opdater' : 'Opret quizz'}
                onClickCreate={justUpdateQuestions ? onQuestionUpdate : onCreate}
                onClickDelete={!justUpdateQuestions && onDeleteButtonClick}
                deleteButtonText="Slet quiz"
                disableCreate={disableButtons()}
                disableButtons={loading} />
        </form>

    )
}
export default SecondCreateCommunityQuizScreen
