import { Auth, Hub } from 'aws-amplify'
import React, { useEffect } from 'react'
import {
    BrowserRouter as Router, Redirect, Route, Switch
} from 'react-router-dom'

import { NotificationPopup, Severity } from '../components/molecules/NotificationPopUp'
import { notificationChannelName } from '../utils/notifications'
import Routes from '../utils/routes'
import { useCognito } from '../utils/Services/cognito-services'
import { getMyDetails } from '../utils/Services/student-services'
import { StudentType } from '../utils/types'
import AppRoute from './AppRoute'
import { StudentQuizItem } from './CommunityQuizItem'
import { CompleteLogInWithUniLogin } from './CompleteLogInWithUniLogin'
import { CompleteSignUpWithUniLogin } from './CompleteSignUpWithUniLogin'
import { Games } from './Games'
import { HomeworkPage } from './Homework'
import { HomeworkItemPage } from './HomeworkItem'
import { Login } from './Login'
import { Settings } from './Settings'
import { SignUp } from './SignUp/SignUp'
import { StudentQuizzes } from './StudentQuiz'

export default function RootRouter() {
    const [user, setUser] = React.useState<StudentType | null>(null)
    const { signedIn } = useCognito()

    const checkLogin = async () => {
        try {
            setUser(await getMyDetails())
        } catch (e) {
            try {
                await Auth.signOut()
            } catch (x) {
                // nothing
            }
        }
    }

    useEffect(() => {
        if (signedIn) {
            checkLogin().then()
        }
    }, [signedIn])

    const [showNotification, setShowNotification] = React.useState(false)
    const [notificationSeverity, setNotificationSeverity] = React.useState<Severity>('info')
    const [notificationMessage, setNotificationMessage] = React.useState('')

    const notificationListener = React.useCallback(({ payload: { data: { message, severity } } }) => {
        setShowNotification(true)
        setNotificationSeverity(severity)
        setNotificationMessage(message)
    }, [])

    React.useEffect(() => {
        Hub.listen(notificationChannelName, notificationListener)
        return () => Hub.remove(notificationChannelName, notificationListener)
    }, [])

    return (
        <>
            <NotificationPopup
                onClose={() => setShowNotification(false)}
                show={showNotification}
                severity={notificationSeverity}
                message={notificationMessage} />
            <Router>
                <Switch>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Lessons.path}>
                        <HomeworkPage student={user} />
                    </AppRoute>

                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Settings.path}>
                        <Settings student={user} />
                    </AppRoute>

                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Settings.path}>
                        <Settings student={user} />
                    </AppRoute>

                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Games.path}>
                        <Games />
                    </AppRoute>

                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Quiz.path}>
                        <StudentQuizzes />
                    </AppRoute>

                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.StudentQuiz.path}>
                        <StudentQuizItem />
                    </AppRoute>

                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.SignUp.path}>
                        <SignUp />
                    </AppRoute>

                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.Login.path}>
                        <Login />
                    </AppRoute>

                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.CompleteSignUpWithUniLogin.path}>
                        <CompleteSignUpWithUniLogin />
                    </AppRoute>

                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.CompleteLogInWithUniLogin.path}>
                        <CompleteLogInWithUniLogin />
                    </AppRoute>

                    <AppRoute type="any" signedIn={signedIn} exact path={`${Routes.Lesson.path}/:id`}>
                        <HomeworkItemPage student={user} />
                    </AppRoute>

                    {signedIn && (
                        <Route key="*" exact path="*">
                            <Redirect to={Routes.Lessons.path} />
                        </Route>
                    )}
                    {!signedIn && (
                        <Route key="*" exact path="*">
                            <Redirect to={Routes.Login.path} />
                        </Route>
                    )}
                </Switch>
            </Router>
        </>
    )
}
