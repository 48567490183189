import { createStyles, makeStyles } from '@material-ui/core'

export const useQuestionStyles = makeStyles(() => createStyles({
    answerButton: {
        height: 40,
        width: 130
    },
    iconWrong: {
        color: 'red',
        fontWeight: 600,
        fontSize: 38
    },
    iconCorrect: {
        color: 'green'
    },
    image: {
        width: 30,
        height: 30,
        marginRight: 5
    }
}))
