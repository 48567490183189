import { createStyles, createTheme, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

export const buttonTheme = createTheme({
    palette: {
        primary: {
            main: green[500],
            contrastText: '#fff' // button text white instead of blac
        }
    },
    typography: {
        button: {
            textTransform: 'none',
        }
    }
})

export const useTableStyles = makeStyles(() => createStyles({
    table: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '85%',
        margin: 'auto',
        marginBottom: 20,
        position: 'relative',
        zIndex: 5,
        minHeight: 0
    },
    tableWithoutBottomButtons: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '85%',
        margin: 'auto',
        marginBottom: '10%',
        position: 'relative',
        zIndex: 5
    },
    divButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 40,
        paddingLeft: 40,
    },
    divInputs: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 30,
        paddingLeft: 30,
        marginTop: 50,
        maxWidth: '85%',
        margin: 'auto'
    },
    divInputRow: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    checkBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    divAuthButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 30,
        flexWrap: 'wrap'
    }
}),)
