import React from 'react'

import { AssignedQuestionType, AssignedQuizType } from '../../utils/types'
import { QuizQuestionLink } from '../atoms/QuizQuestionLink'

interface Props {
    quiz: AssignedQuizType,
    question: AssignedQuestionType,
    videoLoading: boolean
    navigateToQuestion: (question: AssignedQuestionType, index: number) => void,
    setIsAnswered: (value: boolean) => void
}
  
export const QuizQuestions: React.FC<Props> = ({
    quiz,
    question,
    videoLoading,
    navigateToQuestion,
    setIsAnswered
}) => {
    const isQuestionCorrect = (index: number) => {
        const currentQuestion = quiz.questions[index].givenAnswer
        if (currentQuestion) return currentQuestion.correct
        return false
    }

    const isQuestionDisabled = (index: number) => {
        if (videoLoading) return true
        if (index === 0) return false

        if (!quiz.onlyOrderedResponse) {
            return false
        } else {
            // can reach a question in a closed quiz if the previus is answered correctly
            if (isQuestionCorrect(index - 1)) return false
            // cannot reach a question in a closed quiz if the previus isn't answered correctly
            return true
        }
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '30px'
        }}>
            {quiz?.questions && quiz.questions.map((item, index) => (
                <QuizQuestionLink 
                    key={item.title}
                    title={item.title}
                    currentQuestionTitle={question.title}
                    correct={isQuestionCorrect(index)}
                    disabled={isQuestionDisabled(index)}
                    onQuestionItemClick={() => {
                        setIsAnswered(false)
                        navigateToQuestion(item, index) 
                    }} />
            ))}
        </div>
    )
}
