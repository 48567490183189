import { LinearProgress, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export type EditModalTemplateProps = {
    isOpen: boolean
    isBusy?: boolean
    onClose?(): void
    header: string
    className?: any
}

export function getModalStyle() {
    const top = 50
    const left = 50
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}))

export const EditModalTemplate: React.FC<EditModalTemplateProps> = ({
    isOpen,
    isBusy,
    onClose,
    header,
    className,
    children
}) => {
    const [modalStyle] = React.useState(getModalStyle)
    const classes = useStyles()

    const loading = isBusy ? <LinearProgress color="secondary" /> : <></>

    return (
        <Modal
            BackdropProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.4)' } }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={isOpen}
            onClose={() => onClose}>
            <div style={modalStyle} className={className}>
                <div className={classes.root}>
                    {loading}
                </div>
                <h2>{header}</h2>
                {children}
            </div>
        </Modal>

    )
}
